/* eslint-disable jsx-a11y/label-has-associated-control */
// react
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
// mui
import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// redux
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../redux";
// components
import { MenuProps } from "../Form/Forms/FormUtils";
// constants, types and utils
import { BASE_LINK, FORM_REDIRECT_URL } from "../../utils";
// query and mutation
import { gql, useQuery } from "@apollo/client";
import { customFieldImageUpload } from "../../api/clients_sites";
import {
	CLIENTS_DATA,
	CUSTOM_FIELD_COMPLEX_TYPE,
	ENTITY_METADATA,
	ENTITY_OPTIONS,
	PARTIAL_CLIENTS_DATA,
	ROUTE_DATA,
	SITES_DATA,
} from "../../@types";
import ReactSelect from "react-select";
import SearchBox from "../Tasks/Assign/SearchBox";
// libraries
// @ts-ignore
import en from "react-phone-number-input/locale/en";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const GET_FORM_OPTIONS = gql`
	query GetFormByCompanyID {
		get_form_metadata_by_companyID_v2 {
			formTemplateID
			formTitle
		}
	}
`;
const acceptedPhotoTypes = ["jpg", "jpeg", "svg", "png", "bmp"];
const acceptedFileTypes = ["pdf", "xlsx", "doc", "xls", "ppt", "pptx", "txt", "jpg", "jpeg", "svg", "png", "bmp"];

type SELECT_TASK_TYPE = "sites" | "clients" | "tasks" | "routes";
const findTaskLabel = (
	taskType: SELECT_TASK_TYPE,
	id: string,
	sites: any,
	clients: any,
	routes: any,
	placeholder?: string
) => {
	if (!id || !clients || !routes) {
		return placeholder ? { label: placeholder, value: "" } : "";
	}

	if (taskType === "sites") {
		const site: SITES_DATA = sites.find((s: SITES_DATA) => s.clientID === id);
		const obj = {
			label: site?.clientName,
			value: site?.clientID,
		};
		return site ? obj : placeholder ? { label: placeholder, value: "" } : "";
	} else if (taskType === "clients") {
		const client = clients.find((c: PARTIAL_CLIENTS_DATA) => c.clientID === id);
		return client
			? { label: client.clientName, value: client.clientID }
			: placeholder
			? { label: placeholder, value: "" }
			: "";
	} else if (taskType === "routes") {
		const route = routes.find((r: ROUTE_DATA) => r.routeID === id);
		return route
			? {
					label: route.name,
					value: route.routeID,
			  }
			: placeholder
			? { label: placeholder, value: "" }
			: "";
	}
};

// TODO: Employee Type Custom Field
// const GET_ALL_EMPLOYEES = gql`
// 	query {
// 		get_users_by_admin_id {
// 			employeeID
// 			firstName
// 			lastName
// 			profileID
// 			profileName
// 		}
// 	}
// `;

type Props = {
	fieldsData: CLIENTS_DATA | any;
	setFieldsData: Dispatch<SetStateAction<CLIENTS_DATA>> | any;
	missingEntities: ENTITY_METADATA[];
	setImageOrFileError: Dispatch<SetStateAction<any>>;
	setCustomFieldErrors: Dispatch<SetStateAction<any>>;
	isDisabled?: boolean;
	overrideIsRequiredForAdmins?: boolean;
	clients?: PARTIAL_CLIENTS_DATA[];
	routes?: ROUTE_DATA[];
	sites?: SITES_DATA[];
	changeJobType?: any;
	jobType?: any;
	siteTypes?: any;
	filterdSites?: any;
	mapOptions?: any;
	center?: any;
	zoom?: any;
	onMapChanges?: any;
	onMarkerInteraction?: any;
	onMarkerInteractionMouseUp?: any;
	draggable?: any;
	apiHasLoaded?: any;
	mapApiLoaded?: any;
	onPlacesChanged?: any;
	maps?: any;
	googleMap?: any;
	editTask?: boolean;
	selectedTaskType?: string;
	showMaskedData?: boolean;
	clientMasking?: any;
	isClientAddressMasked?: any;
};
const CustomFieldsComplex: FC<Props> = ({
	fieldsData,
	setFieldsData,
	missingEntities,
	setImageOrFileError,
	setCustomFieldErrors,
	isDisabled,
	overrideIsRequiredForAdmins,
	clients,
	sites,
	routes,
	changeJobType,
	jobType,
	siteTypes,
	filterdSites,
	mapOptions,
	center,
	zoom,
	onMapChanges,
	onMarkerInteraction,
	onMarkerInteractionMouseUp,
	draggable,
	apiHasLoaded,
	mapApiLoaded,
	onPlacesChanged,
	maps,
	googleMap,
	editTask,
	selectedTaskType = "Default",
	clientMasking,
	showMaskedData,
	isClientAddressMasked,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();

	// state
	const [formTemplates, setFormTemplates] = useState<any[]>([]);
	const isTaskCompleted = fieldsData?.checkinTime && fieldsData?.checkoutTime ? true : false;
	// const { data: employeeList, loading: employeeListLoading } = useQuery(GET_ALL_EMPLOYEES);
	const { data: formData, loading: formDataLoading } = useQuery(GET_FORM_OPTIONS);

	// for custom task only
	const [selectedOptionForClient, setSelectedOptionForClient] = useState<any>("client");
	const [selectedOptionForSite, setSelectedOptionForSite] = useState<any>("site");
	// effects
	useEffect(() => {
		if (formData?.get_form_metadata_by_companyID_v2) {
			setFormTemplates(formData?.get_form_metadata_by_companyID_v2);
		}
	}, [formData, formDataLoading]);

	// functions
	const handlePhotoOrFileUpload = async (
		event: any,
		entity: any,
		currentField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined
	) => {
		const file = event.target.files ? event.target.files[0] : null;
		const extension = file?.name.split(".").reverse()[0];
		const customFileName = `${uuidv4()}.${extension}`;
		const customFieldDataType = entity.dataType;

		if (!file) return;
		if (currentField?.selectedFieldValues && currentField?.selectedFieldValues?.length >= 3) {
			dispatch(showNotification({ message: `Max 3 ${customFieldDataType}'s can be uploaded`, severity: "error" }));
			event.target.value = "";
			// also clear the file input
			event.target.files = null;
			return;
		}
		if (customFieldDataType === "photo" && file.size > 3000000) {
			dispatch(showNotification({ message: "File size should not be greater than 3MB", severity: "error" }));
			return;
		}
		if (customFieldDataType === "file" && file.size > 5000000) {
			dispatch(showNotification({ message: "File size should not be greater than 5MB", severity: "error" }));
			return;
		}
		if (customFieldDataType === "photo" && !acceptedPhotoTypes.includes(extension)) {
			dispatch(showNotification({ message: "Incorrect: File should be .jpg/.jpeg/.svg/.png/.bmp", severity: "error" }));
			return;
		}
		if (customFieldDataType === "file" && !acceptedFileTypes.includes(extension)) {
			dispatch(
				showNotification({
					message: "Incorrect: File should be .pdf/.xlsx/.doc/.docx/.xls/.ppt/.pptx/.txt/.jpg/.jpeg/.svg/.png/.bmp",
					severity: "error",
				})
			);
			return;
		}
		// setImageLoading(true);
		const { status, imageUrl } = await customFieldImageUpload(file, customFileName, "Client");
		let isUpload = false;
		if (status === "success") {
			isUpload = true;
		}
		dispatch(
			showNotification({
				message: isUpload
					? `${customFieldDataType === "photo" ? "Image" : "File"} Uploaded Successfully`
					: `${customFieldDataType === "photo" ? "Image" : "File"} Upload Failed`,
				severity: isUpload ? "success" : "error",
			})
		);

		if (isUpload) {
			handleChangeCustomFields(entity, event.target.value, event.target.name, file, imageUrl);
		}

		event.target.value = "";
		// also clear the file input
		event.target.files = null;
	};
	const handleAscOrderArrangement = (props: ENTITY_OPTIONS[]) =>
		props.sort((a, b) => a.optionValue.localeCompare(b.optionValue));

	const deleteImageOrFileFromList = (dataToDelete: any, entity: ENTITY_METADATA, index?: number) => {
		const newClient = _.cloneDeep(fieldsData);

		if (newClient.customFieldsComplex) {
			const existingFieldIndex = newClient.customFieldsComplex.findIndex(
				(item: any) => item.fieldID === entity.fieldID
			);

			if (existingFieldIndex !== -1) {
				newClient.customFieldsComplex[existingFieldIndex].selectedFieldValues.splice(index, 1);
			}
			// Check if the array is empty
			// if (newClient.customFieldsComplex[existingFieldIndex].selectedFieldValues?.length === 0) {
			// 	// If empty, delete the field from customFieldsComplex
			// 	newClient.customFieldsComplex.splice(existingFieldIndex, 1);
			// }
		}

		setFieldsData(newClient);
	};

	const customFormatOptionLabel = (option: any) => (
		<div>
			<div
				style={{
					paddingTop: "5px",
					color: "#42444a",
					fontSize: "14px",
					fontWeight: 400,
				}}>
				{option.label.charAt(0).toUpperCase() + option.label.slice(1)}
			</div>
			<p
				style={{
					paddingTop: "5px",
					color: "gray",
					fontSize: "13px",
					lineHeight: "20px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}>
				{isClientAddressMasked === 1 && option?.address
					? "**** **** **"
					: option?.address?.length > 80
					? option?.address?.slice(0, 80) + "..."
					: option.address}
			</p>
		</div>
	);

	const formatDateToDayJs = (date: string) => {
		if (dayjs(date, "DD-MM-YYYY").isValid()) {
			return dayjs(date, "DD-MM-YYYY");
		}
		if (dayjs(date, "YYYY-MM-DD").isValid()) {
			return dayjs(date);
		}
		return null;
	};
	const getInputFields = (entity: ENTITY_METADATA, field: CUSTOM_FIELD_COMPLEX_TYPE[] | null | undefined) => {
		const map = new Map<typeof entity.dataType, JSX.Element>();
		const currentField = field?.find((f) => f?.fieldID === entity.fieldID);
		const selectedForm = formTemplates?.find((f) => f.formTemplateID === entity.argsOne);
		const customSelectedForm = formTemplates?.find((f) => f.formTemplateID === entity?.options?.[0]?.optionValue);
		const isRequired = overrideIsRequiredForAdmins ? false : entity.isRequired === 0 ? false : true;
		const validClientIDs = clients?.map((client) => client.clientID); //taking the clientID into account which are visible to the employee
		map.set(
			"varchar",
			<TextField
				sx={{ marginBottom: "10px" }}
				key={currentField?.fieldID}
				value={entity?.maskField && !showMaskedData ? "****" : currentField?.fieldValue || ""}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={isRequired}
				disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
			/>
		);
		map.set(
			"number",
			<TextField
				sx={{ marginBottom: "10px" }}
				key={currentField?.fieldID}
				type={entity?.maskField && !showMaskedData ? "string" : "number"}
				value={entity?.maskField && !showMaskedData ? "****" : currentField?.fieldValue ?? ""}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={isRequired}
				disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
			/>
		);
		map.set(
			"address",
			<TextField
				sx={{ marginBottom: "10px" }}
				key={currentField?.fieldID}
				value={entity?.maskField && !showMaskedData ? "****" : currentField?.fieldValue}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={isRequired}
				disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
			/>
		);
		map.set(
			"dropDown",
			<FormControl
				fullWidth
				key={currentField?.fieldID}
				sx={{ marginBottom: 0 }}
				required={isRequired}
				disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}>
				<InputLabel id={entity.fieldID}>{entity.fieldName}</InputLabel>
				<Select
					value={
						entity?.maskField && !showMaskedData
							? "****"
							: currentField?.selectedOptions
							? Array.isArray(currentField?.selectedOptions)
								? currentField?.selectedOptions[0]
								: currentField?.selectedOptions
							: ""
					}
					labelId={entity.fieldID}
					label={entity.fieldName}
					onChange={(e) => {
						handleChangeCustomFields(entity, e.target.value);
					}}
					required={isRequired}>
					{entity.options &&
						Array.isArray(entity.options) &&
						handleAscOrderArrangement(entity.options).map((item) => {
							if (item.softDeleted && currentField?.selectedOptions?.includes(item.optionID)) {
								return (
									<MenuItem key={item.optionID} value={item.optionID}>
										{item.optionValue}
									</MenuItem>
								);
							}
							if (!item.softDeleted) {
								return (
									<MenuItem key={item.optionID} value={item.optionID}>
										{item.optionValue}
									</MenuItem>
								);
							}
						})}
					{entity?.maskField && !showMaskedData ? <MenuItem value="****">****</MenuItem> : null}
				</Select>
			</FormControl>
		);
		map.set(
			"status",
			<FormControl
				fullWidth
				key={currentField?.fieldID}
				sx={{ marginBottom: 0 }}
				required={isRequired}
				disabled={isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}>
				<InputLabel id={entity.fieldID}>{entity.fieldName}</InputLabel>
				<Select
					value={
						currentField?.selectedOptions
							? Array.isArray(currentField?.selectedOptions)
								? currentField?.selectedOptions[0]
								: currentField?.selectedOptions
							: ""
					}
					labelId={entity.fieldID}
					label={entity.fieldName}
					onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
					required={isRequired}>
					{entity.options &&
						Array.isArray(entity.options) &&
						entity.options.map((item) => (
							<MenuItem key={item.optionID} value={item.optionID}>
								{item.optionValue}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		);
		map.set(
			"checkbox",
			<FormControl
				fullWidth
				key={currentField?.fieldID}
				required={isRequired}
				disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}>
				{entity?.maskField && !showMaskedData ? (
					<TextField sx={{ marginBottom: "10px" }} value={"****"} label={entity.fieldName} disabled={true} />
				) : (
					<>
						<InputLabel id={entity.fieldID}>{entity.fieldName}</InputLabel>
						<Select
							sx={{ marginBottom: "10px" }}
							labelId={entity.fieldID}
							label={entity.fieldName}
							onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
							multiple
							value={
								entity?.maskField && !showMaskedData
									? []
									: currentField?.selectedOptions
									? Array.isArray(currentField?.selectedOptions)
										? currentField?.selectedOptions
										: [currentField?.selectedOptions]
									: []
							}
							required={isRequired}
							input={<OutlinedInput label={entity.fieldName} />}
							renderValue={(selected: number | number[]) =>
								entity?.maskField && !showMaskedData
									? "****"
									: entity.options &&
									  getOptionValuesFromOptionIds(Array.isArray(selected) ? selected : [selected], entity.options).join(
											", "
									  )
							}
							MenuProps={MenuProps}>
							{entity.options &&
								Array.isArray(entity.options) &&
								handleAscOrderArrangement(entity.options).map((item) => {
									if (item.softDeleted && currentField?.selectedOptions?.includes(item.optionID)) {
										return (
											<MenuItem key={item.optionID} value={item.optionID}>
												<Checkbox checked={currentField?.selectedOptions?.includes(item.optionID)} />
												<ListItemText primary={item.optionValue} />
											</MenuItem>
										);
									}
									if (!item.softDeleted) {
										return (
											<MenuItem key={item.optionID} value={item.optionID}>
												<Checkbox checked={currentField?.selectedOptions?.includes(item.optionID)} />
												<ListItemText primary={item.optionValue} />
											</MenuItem>
										);
									}
								})}
						</Select>
					</>
				)}
			</FormControl>
		);
		map.set(
			"date",
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				{entity?.maskField && !showMaskedData ? (
					<TextField sx={{ marginBottom: "10px" }} value={"****"} label={entity.fieldName} disabled={true} />
				) : (
					<DesktopDatePicker
						label={`${entity.fieldName}`}
						disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
						format="DD-MM-YYYY"
						value={currentField?.fieldValue ? formatDateToDayJs(currentField?.fieldValue) : null}
						onChange={(e: Dayjs | null) => handleChangeCustomFields(entity, dayjs(e))}
						slotProps={{ textField: { required: isRequired } }}
					/>
				)}
			</LocalizationProvider>
		);
		map.set(
			"contact",
			<section className="contact_details_container" key={currentField?.fieldID}>
				<span className="container_title">{entity.fieldName}</span>
				<TextField
					label="Contact Name"
					name="contactName"
					required={isRequired}
					disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
					value={entity?.maskField && !showMaskedData ? "****" : currentField?.fieldValue ?? ""}
					onChange={(e) => handleChangeCustomFields(entity, e.target.value, e.target.name)}
					inputProps={{ maxLength: 90 }}
					helperText={`${currentField?.fieldValue ? currentField?.fieldValue?.length : 0}/${90}`}
					sx={{ width: "100%" }}
				/>

				<div className="contact_sub_container">
					<FormControl
						required={isRequired}
						disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}>
						<InputLabel id="clientCat">Code</InputLabel>
						<Select
							value={entity?.maskField && !showMaskedData ? "*****" : currentField?.fieldValue2 ?? ""}
							labelId="countryCode"
							name="countryCode"
							label="Country Code"
							onChange={(e) => handleChangeCustomFields(entity, e.target.value, e.target.name)}>
							{/* Create a Menu Item that marks as blank */}
							<MenuItem value={""}>
								<em>None</em>
							</MenuItem>
							{getCountries().map((country) => (
								<MenuItem key={country} value={getCountryCallingCode(country)}>
									{`${en[country]} +${getCountryCallingCode(country)}`}
								</MenuItem>
							))}
							{entity?.maskField && !showMaskedData && <MenuItem value="*****">*****</MenuItem>}
						</Select>
					</FormControl>

					<TextField
						label="Contact Number"
						name="contactNumber"
						required={isRequired}
						disabled={clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
						error={
							currentField?.fieldValue3 === "" || currentField?.fieldValue3 === undefined
								? false
								: !isValidPhoneNumber(`+${currentField?.fieldValue2}${currentField?.fieldValue3}`)
						}
						value={entity?.maskField && !showMaskedData ? "****" : currentField?.fieldValue3 ?? ""}
						onChange={(e) => handleChangeCustomFields(entity, e.target.value, e.target.name)}
					/>
				</div>
			</section>
		);
		map.set(
			"photo",
			<section className="photo_container">
				<div className="container_header">
					<span className="container_title">
						{entity.fieldName} {isRequired ? "*" : ""}{" "}
						<b style={{ color: "gray", fontSize: "12px", fontWeight: "normal" }}>(Photo)</b>
					</span>
					<div>
						<FormControl
							required={isRequired}
							disabled={
								clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled
							}>
							<label
								htmlFor={`${entity.fieldName}`}
								className={
									clientMasking === 1
										? "label_is_disabled"
										: isTaskCompleted
										? entity?.allowAdminToEditAfterSubmit
											? ""
											: "label_is_disabled"
										: isDisabled
										? "label_is_disabled"
										: ""
								}>
								<FileUploadOutlinedIcon />

								<input
									type="file"
									accept="image/*" // You can specify the accepted file types
									onChange={(e) => handlePhotoOrFileUpload(e, entity, currentField)}
									name={entity.fieldName}
									id={entity.fieldName}
									hidden={true}
									disabled={
										clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled
									}
								/>
							</label>
						</FormControl>
					</div>
				</div>
				{currentField?.selectedFieldValues &&
					currentField?.selectedFieldValues?.length > 0 &&
					(entity?.maskField ? showMaskedData : true) && (
						<div className="uploaded_image_container">
							{currentField?.selectedFieldValues?.map((each: any, index) => (
								<div className="individual_container" key={each.fieldValue}>
									<button
										title={`Delete photo ${each.fieldValue2}`}
										className="delete_image_action"
										onClick={(e) => {
											e.preventDefault();
											deleteImageOrFileFromList(each, entity, index);
										}}
										type="button"
										disabled={
											clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled
										}>
										<DeleteIcon />
									</button>
									<img src={each.fieldValue} alt={each.fieldValue2} />
									{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
									<span role="button" tabIndex={0} onClick={() => window.open(each.fieldValue, "_blank")}>
										{each.fieldValue2}
									</span>
								</div>
							))}
						</div>
					)}
				{entity?.maskField && !showMaskedData ? (
					<div className="uploaded_image_container" style={{ color: "gray" }}>
						****
					</div>
				) : null}
			</section>
		);
		map.set(
			"file",
			<section className="photo_container">
				<div className="container_header">
					<span className="container_title">
						{entity.fieldName} {isRequired ? "*" : ""}{" "}
						<b style={{ color: "gray", fontSize: "12px", fontWeight: "normal" }}>(File)</b>
					</span>
					<div>
						<label
							htmlFor={entity.fieldName}
							className={
								clientMasking === 1
									? "label_is_disabled"
									: isTaskCompleted
									? entity?.allowAdminToEditAfterSubmit
										? ""
										: "label_is_disabled"
									: isDisabled
									? "label_is_disabled"
									: ""
							}>
							<FileUploadOutlinedIcon />

							<input
								type="file"
								accept="image/jpeg, image/png, image/jpg, image/bmp, image/svg+xml, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain" // You can specify the accepted file types
								onChange={(e) => handlePhotoOrFileUpload(e, entity, currentField)}
								name={entity.fieldName}
								required={isRequired}
								id={entity.fieldName}
								hidden={true}
								disabled={
									clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled
								}
							/>
						</label>
					</div>
				</div>
				{currentField?.selectedFieldValues &&
					currentField?.selectedFieldValues?.length > 0 &&
					(entity?.maskField ? showMaskedData : true) && (
						<div className="uploaded_files_container">
							{currentField?.selectedFieldValues?.map((each: any, index: any) => (
								<div className="individual_container" key={each.fieldValue}>
									{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
									<span role="button" tabIndex={0} onClick={() => window.open(each.fieldValue, "_blank")}>
										{each.fieldValue2}
									</span>
									<button
										title={`Delete file ${each.fieldValue2}`}
										className="delete_image_action"
										onClick={(e) => {
											e.preventDefault();
											deleteImageOrFileFromList(each, entity, index);
										}}
										disabled={
											clientMasking === 1 ? true : isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled
										}>
										<DeleteIcon />
									</button>
								</div>
							))}
						</div>
					)}
				{entity?.maskField && !showMaskedData ? (
					<div className="uploaded_files_container" style={{ color: "gray" }}>
						****
					</div>
				) : null}
			</section>
		);
		map.set(
			"form",
			<div className="contact_details_container">
				<span className="container_title">{entity?.fieldName}</span>
				{currentField && (
					<div className="selected_form_container">
						<div className="selected_form_container_row">
							<b>Form Name</b>
							<span>
								{selectedTaskType === "Default"
									? selectedForm && selectedForm?.formTitle
									: customSelectedForm && customSelectedForm?.formTitle}
							</span>
						</div>
						{selectedTaskType === "Default" && (
							<div className="selected_form_container_row">
								<b>Status</b>
								<span>
									{currentField?.fieldValue && currentField?.fieldValue2 ? (
										<a
											href={`${FORM_REDIRECT_URL}/${currentField?.fieldValue}`}
											target="_blank"
											rel="noreferrer"
											style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px" }}>
											Completed <LaunchIcon style={{ fontSize: "15px" }} />
										</a>
									) : (
										"Not Filled"
									)}
								</span>
							</div>
						)}
						{selectedTaskType !== "Default" && (
							<div className="selected_form_container_row">
								<b>Status</b>
								<span>
									{currentField?.fieldValue ? (
										<a
											href={`${FORM_REDIRECT_URL}/${currentField?.fieldValue}`}
											target="_blank"
											rel="noreferrer"
											style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px" }}>
											Completed <LaunchIcon style={{ fontSize: "15px" }} />
										</a>
									) : (
										"Not Filled"
									)}
								</span>
							</div>
						)}
					</div>
				)}

				<i className="form_custom_field_note">Note: Forms can only be filled by employees</i>
			</div>
		);
		map.set(
			"order",
			<div className="contact_details_container">
				<span className="container_title">{entity?.fieldName}</span>
				{currentField && (
					<div className="selected_form_container">
						<div className="selected_form_container_row">
							<b>Order Placed</b>
							<span>
								<a
									href={`${BASE_LINK}/order/orders`}
									target="_blank"
									rel="noreferrer"
									style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px" }}>
									Order Link <LaunchIcon style={{ fontSize: "15px" }} />
								</a>
							</span>
						</div>
					</div>
				)}

				<i className="form_custom_field_note">Note: Order can only be placed by employees</i>
			</div>
		);
		map.set(
			"client",
			<>
				<FormControl fullWidth>
					<InputLabel>Task Against</InputLabel>
					<Select
						value={selectedOptionForClient}
						disabled={editTask}
						onChange={(e) => setSelectedOptionForClient(e.target.value)}
						required={isRequired}>
						<MenuItem value={"client"}>Client</MenuItem>
						{!editTask && <MenuItem value={"route"}>Routes</MenuItem>}
					</Select>
				</FormControl>
				{selectedOptionForClient === "client" && (
					<ReactSelect
						value={findTaskLabel("clients", currentField?.fieldValue!, sites, clients, routes, "Search Clients")}
						onChange={(e) => handleChangeCustomFields(entity, e, "clientID")}
						isClearable={true}
						placeholder={"Search Clients"}
						className="react-select"
						isDisabled={isDisabled}
						styles={{
							menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
							menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
							option: (provided: any, state: any) => ({
								...provided,
								color: "black",
								backgroundColor: state.isSelected ? "#e8edff" : "#fff",
								"&:hover": {
									backgroundColor: "#e8edff",
								},
							}),
						}}
						options={clients
							?.filter((client: any) => client?.visibility !== null)
							?.map((i: PARTIAL_CLIENTS_DATA) => ({
								value: i.clientID,
								label: i.clientName,
								lat: i.lat,
								lng: i.lng,
								address: i.address,
							}))}
						formatOptionLabel={customFormatOptionLabel}
					/>
				)}
				{!editTask && selectedOptionForClient === "route" && (
					<ReactSelect
						onChange={(e) => handleChangeCustomFields(entity, e, "routeID")}
						isClearable={true}
						value={
							fieldsData?.routeID
								? findTaskLabel("routes", fieldsData?.routeID, sites, clients, routes, "Search Routes")
								: ""
						}
						isDisabled={isDisabled}
						styles={{
							menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
							menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
						}}
						placeholder={"Search Routes"}
						className="react-select"
						options={routes
							?.filter((route) =>
								route?.routeDetails?.every(
									(r) => r.type === "Client" && validClientIDs?.includes(r.client.clientID)// filtering out routes on the basis of the client visibility
								)
							)
							?.map((i: ROUTE_DATA) => ({
								value: i.routeID,
								label: i.name,
							}))}
					/>
				)}
				<p
					style={{
						padding: "5px",
						color: "gray",
						fontSize: "14px",
						lineHeight: "20px",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					}}>
					Note: You can select only one of client or routes
				</p>
			</>
		);
		map.set(
			"site",
			<>
				<>
					<FormControl fullWidth>
						<InputLabel>Task Against</InputLabel>
						<Select
							value={selectedOptionForSite}
							disabled={editTask}
							onChange={(e) => setSelectedOptionForSite(e.target.value)}
							required={isRequired}>
							<MenuItem value={"site"}>Site</MenuItem>
							{!editTask && <MenuItem value={"route"}>Routes</MenuItem>}
						</Select>
					</FormControl>
					{selectedOptionForSite === "site" && (
						<>
							<ReactSelect
								styles={{
									menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
									menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
								}}
								value={jobType ?? ""}
								onChange={changeJobType}
								isClearable={true}
								isDisabled={isDisabled}
								placeholder="Search Site Type"
								className="react-select"
								options={siteTypes?.map((i: any) => ({
									value: i.jobTypeID,
									label: i.jobDescription,
								}))}
							/>
							<ReactSelect
								styles={{
									menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
									menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
								}}
								value={
									fieldsData.clientInfo?.clientName
										? { label: fieldsData.clientInfo?.clientName, value: fieldsData.clientInfo?.clientID }
										: ""
								}
								onChange={(e) => handleChangeCustomFields(entity, e, "siteTypeID")}
								isClearable={true}
								placeholder={"Search Sites"}
								className="react-select"
								isDisabled={isDisabled}
								options={filterdSites?.map((i: SITES_DATA) => ({
									value: i.clientID,
									label: i.clientName,
									lat: i.latitude,
									lng: i.longitude,
									address: i.address,
								}))}
							/>
						</>
					)}

					{!editTask && selectedOptionForSite === "route" && (
						<ReactSelect
							onChange={(e) => handleChangeCustomFields(entity, e, "routeID")}
							isClearable={true}
							value={
								fieldsData?.routeID
									? findTaskLabel("routes", fieldsData?.routeID, sites, clients, routes, "Search Routes")
									: ""
							}
							isDisabled={isDisabled}
							styles={{
								menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
								menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
							}}
							placeholder={"Search Routes"}
							className="react-select"
							options={routes
								?.filter((route) => route?.routeDetails.every((route) => route.type === "Site"))
								?.map((i: ROUTE_DATA) => ({
									value: i.routeID,
									label: i.name,
								}))}
						/>
					)}

					<p
						style={{
							padding: "5px",
							color: "gray",
							fontSize: "14px",
							lineHeight: "20px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}>
						Note: You can select only one of sites or routes
					</p>
				</>
			</>
		);
		map.set(
			"metaLocation",
			<>
				<div className="map" id="map2">
					{mapApiLoaded && (
						<SearchBox
							onPlacesChanged={onPlacesChanged}
							onChange={(e: any) => {
								setFieldsData({ ...fieldsData, address: e.target.value });
							}}
							maps={maps}
							isDisabled={isDisabled}
							value={fieldsData.address}
							map={googleMap}
						/>
					)}
					<GoogleMapReact
						bootstrapURLKeys={{
							key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
							libraries: ["places"],
						}}
						options={mapOptions}
						center={center}
						zoom={zoom}
						yesIWantToUseGoogleMapApiInternals
						onChange={(e) => onMapChanges(e.center, e.zoom)}
						onChildMouseDown={onMarkerInteraction}
						onChildMouseUp={onMarkerInteractionMouseUp}
						onChildMouseMove={onMarkerInteraction}
						draggable={draggable}
						onGoogleApiLoaded={({ map, maps }) => {
							apiHasLoaded(map, maps);
						}}>
						<Marker lat={fieldsData.lat || 22.593684} lng={fieldsData.lon || 81.96288} />
					</GoogleMapReact>
				</div>
			</>
		);
		map.set(
			"time",
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TimePicker
					viewRenderers={{
						hours: renderTimeViewClock,
						minutes: renderTimeViewClock,
						seconds: renderTimeViewClock,
					}}
					sx={{ marginBottom: "10px" }}
					key={currentField?.fieldID}
					value={
						currentField?.fieldValue &&
						dayjs(
							typeof currentField?.fieldValue === "string"
								? // convert string to number
								  +currentField?.fieldValue
								: currentField?.fieldValue
						).isValid()
							? dayjs(
									typeof currentField?.fieldValue === "string"
										? // convert string to number
										  +currentField?.fieldValue
										: currentField?.fieldValue
							  )
							: null
					}
					label={entity.fieldName}
					onChange={(e) => {
						const time = e && e.isValid() ? e.valueOf() : null;
						handleChangeCustomFields(entity, time);
					}}
					// required={isRequired}
					disabled={isTaskCompleted ? !entity?.allowAdminToEditAfterSubmit : isDisabled}
				/>
				{isRequired && !currentField?.fieldValue && (
					<p style={{ color: "red", fontSize: "12px" }}>{entity.fieldName} is required</p>
				)}
			</LocalizationProvider>
		);
		return map.get(entity.dataType);
	};

	const handleChangeCustomFields = (
		entity: ENTITY_METADATA,
		value: any,
		name?: string,
		file?: any,
		imageUrl?: string
	) => {
		const { fieldID, fieldName, dataType } = entity;
		const newClient = { ...fieldsData };
		const existingFieldIndex = newClient.customFieldsComplex?.findIndex((item: any) => item.fieldID === fieldID);
		const isText = ["varchar", "number", "address", "date", "contact", "time"]?.includes(dataType);
		const isFile = ["photo", "file"]?.includes(dataType);
		const isLocation = ["client", "site", "metaLocation"]?.includes(dataType);
		if (
			existingFieldIndex !== undefined &&
			!isNaN(existingFieldIndex) &&
			existingFieldIndex >= 0 &&
			newClient.customFieldsComplex
		) {
			if (isText) {
				// contact type custom field
				if (dataType === "contact") {
					if (name === "contactName")
						newClient.customFieldsComplex[existingFieldIndex].fieldValue = Array.isArray(value)
							? value.join(",")
							: typeof value === "string"
							? value
							: value.toString();
					else if (name === "countryCode")
						newClient.customFieldsComplex[existingFieldIndex].fieldValue2 = Array.isArray(value)
							? value.join(",")
							: typeof value === "string"
							? value
							: value.toString();
					else if (name === "contactNumber")
						newClient.customFieldsComplex[existingFieldIndex].fieldValue3 = Array.isArray(value)
							? value.join(",")
							: typeof value === "string"
							? value.replace(/[^0-9]/g, "")
							: value.replace(/[^0-9]/g, "").toString();
				} else if (dataType === "date") {
					newClient.customFieldsComplex[existingFieldIndex].fieldValue = Array.isArray(value)
						? value.join(",")
						: typeof value === "string"
						? value
						: value;
				} else if (dataType === "time") {
					newClient.customFieldsComplex[existingFieldIndex].fieldValue =
						typeof value === "number" ? value.toString() : null;
				} else {
					newClient.customFieldsComplex[existingFieldIndex].fieldValue = Array.isArray(value)
						? value.join(",")
						: typeof value === "string"
						? value
						: value.toString();
				}
				newClient.customFieldsComplex[existingFieldIndex].selectedOptions = null;
				newClient.customFieldsComplex[existingFieldIndex].selectedOptionsValues = null;
				newClient.customFieldsComplex[existingFieldIndex].selectedFieldValues = null;
			} else if (isFile) {
				newClient.customFieldsComplex[existingFieldIndex].selectedOptions = null;
				newClient.customFieldsComplex[existingFieldIndex].selectedOptionsValues = null;
				newClient.customFieldsComplex[existingFieldIndex].selectedFieldValues = [
					...(newClient.customFieldsComplex[existingFieldIndex]?.selectedFieldValues ?? []), // Use an empty array if it's null or undefined
					{
						fieldValue: imageUrl,
						fieldValue2: file?.name,
					},
				];
			} else if (isLocation) {
				if (name === "routeID") {
					newClient.routeID = value?.value;
					newClient.customFieldsComplex[existingFieldIndex].fieldValue = null;
					newClient.customFieldsComplex[existingFieldIndex].selectedOptions = null;
					newClient.clientInfo = null;
				} else if (name === "clientID") {
					newClient.routeID = null;
					newClient.customFieldsComplex[existingFieldIndex].fieldValue = value?.value;
					newClient.customFieldsComplex[existingFieldIndex].selectedOptions = null;
					newClient.clientID = value?.value;
					newClient.lat = value?.lat;
					newClient.lon = value?.lng;
					newClient.address = value?.address;
					newClient.clientInfo = {
						employeeID: dataType === "client" ? value?.employeeID : -2,
						clientName: value?.label,
						clientID: value?.value,
					};
				} else if (name === "siteTypeID") {
					newClient.routeID = null;
					newClient.customFieldsComplex[existingFieldIndex].fieldValue = value?.value;
					newClient.customFieldsComplex[existingFieldIndex].selectedOptions = null;
					newClient.clientID = value?.value;
					newClient.lat = value?.lat;
					newClient.lon = value?.lng;
					newClient.address = value?.address;
					newClient.clientInfo = {
						employeeID: dataType === "client" ? value?.employeeID : -2,
						clientName: value?.label,
						clientID: value?.value,
					};
				}
			} else {
				const optionIds = Array.isArray(value) ? value.map((i) => +i) : [+value];
				newClient.customFieldsComplex[existingFieldIndex].fieldValue = null;
				newClient.customFieldsComplex[existingFieldIndex].selectedOptions = optionIds;
				newClient.customFieldsComplex[existingFieldIndex].selectedOptionsValues = getOptionValuesFromOptionIds(
					optionIds,
					entity.options as ENTITY_OPTIONS[]
				);
			}
		} else {
			const optionIds = Array.isArray(value) ? value.map((i) => +i) : [+value];
			const newField: any = {
				fieldID: fieldID,
				fieldValue: isText
					? name === "contactName"
						? (value as string)
						: dataType === "date"
						? value
						: dataType === "time"
						? value.toString()
						: null
					: null,
				fieldValue2: isText ? (name === "countryCode" ? (value as string) : null) : null,
				fieldValue3: isText ? (name === "contactNumber" ? (value as string) : null) : null,
				fieldName: fieldName,
				selectedOptions: !isText && !isFile ? (Array.isArray(value) ? optionIds : [+value]) : null,
				selectedOptionsValues:
					!isText && !isFile ? getOptionValuesFromOptionIds(optionIds, entity.options as ENTITY_OPTIONS[]) : null,
				selectedFieldValues: isFile
					? [
							{
								fieldValue: imageUrl,
								fieldValue2: file?.name,
							},
					  ]
					: null,
			};
			// added for custom tasks as custom tasks can contain route, client, site based tasks
			if (isLocation) {
				if (name === "routeID") {
					newClient.routeID = value?.value;
					newField.fieldValue = null;
					newField.selectedOptions = null;
					newClient.clientInfo = null;
				} else if (name === "clientID") {
					newClient.routeID = null;
					newField.fieldValue = value?.value;
					newField.selectedOptions = null;
					newClient.clientID = value?.value;
					newClient.lat = value?.lat;
					newClient.lon = value?.lng;
					newClient.address = value?.address;
					newClient.clientInfo = {
						employeeID: dataType === "client" ? value?.employeeID : -2,
						clientName: value?.label,
						clientID: value?.value,
					};
				} else if (name === "siteTypeID") {
					newClient.routeID = null;
					newField.fieldValue = value?.value;
					newField.selectedOptions = null;
					newClient.clientID = value?.value;
					newClient.lat = value?.lat;
					newClient.lon = value?.lng;
					newClient.address = value?.address;
					newClient.clientInfo = {
						employeeID: dataType === "client" ? value?.employeeID : -2,
						clientName: value?.label,
						clientID: value?.value,
					};
				}
			}
			if (newClient.customFieldsComplex && Array.isArray(newClient.customFieldsComplex)) {
				newClient.customFieldsComplex.push(newField);
			} else {
				newClient.customFieldsComplex = [newField];
			}
		}

		setFieldsData(newClient);
	};

	const getOptionValuesFromOptionIds = (optionIds: number[], options: ENTITY_OPTIONS[]) => {
		const optionsValues = options?.filter((item) => optionIds?.includes(item.optionID));
		return optionsValues?.map((item) => item.optionValue) || [];
	};

	// effects
	useMemo(() => {
		// skip validation for task module
		const photosAndFilesToValidate = missingEntities?.filter(
			(d: any) => (d.dataType === "photo" || d.dataType === "file") && d.entity !== "Tasks"
		);

		const contactToValidate = missingEntities?.filter(
			(d: any) => d.dataType === "contact" && d.fieldName !== "Contact"
		);

		const errorList: string[] = [];
		const contactErrorList: string[] = [];

		const list = photosAndFilesToValidate?.map((each: any) => {
			const data = fieldsData.customFieldsComplex?.find(
				(item: any) => each.fieldID === item.fieldID
			)?.selectedFieldValues;
			return {
				...each,
				numberOfFilesToUpload: data ? (Array.isArray(data) ? data?.length : 0) : 0,
			};
		});
		list?.forEach((each: any) => {
			if (each.active === 1 && each.isRequired === 1 && each?.numberOfFilesToUpload === 0) {
				errorList.push(each.fieldName);
			}
		});

		const contactList = contactToValidate?.map((each: any) => {
			const contactName = fieldsData.customFieldsComplex?.find(
				(item: any) => each.fieldID === item.fieldID
			)?.fieldValue;
			const countryCode = fieldsData.customFieldsComplex?.find(
				(item: any) => each.fieldID === item.fieldID
			)?.fieldValue2;
			const contactNumber = fieldsData.customFieldsComplex?.find(
				(item: any) => each.fieldID === item.fieldID
			)?.fieldValue3;
			return {
				...each,
				contactName,
				countryCode,
				contactNumber,
			};
		});
		contactList?.forEach((each: any) => {
			// overrideIsRequiredForAdmins = "if true then isRequired is not applicable for admins "
			const isRequired = overrideIsRequiredForAdmins ? false : each.isRequired === 0 ? false : true;
			// if contact field is required and active then check if all fields are filled or not
			if (each.active === 1 && isRequired && !each?.contactData) {
				if (each?.contactName?.length === 0) {
					contactErrorList.push(`${each.fieldName} must have a Contact Name`);
				}
				if (each?.countryCode?.length === 0 || each?.countryCode === null) {
					contactErrorList.push(`${each.fieldName} must have a Country Code`);
				}
				if (each?.contactNumber?.length === 0) {
					contactErrorList.push(`${each.fieldName} must have a Contact Number`);
				}
				if (each?.contactNumber?.length > 0 && !isValidPhoneNumber(`+${each?.countryCode}${each?.contactNumber}`)) {
					contactErrorList.push(`${each.fieldName} must have a valid Contact Number`);
				}
			} else if (each.active === 1 && !isRequired && !each?.contactData) {
				// if contact field is not required and active
				if (each?.contactName?.length > 0 || each?.countryCode?.length > 0 || each?.contactNumber?.length > 0) {
					// if any of the field is filled then check if all fields are filled or not
					if (each?.contactName?.length === 0) {
						contactErrorList.push(`${each.fieldName} must have a Contact Name`);
					}
					if (each?.countryCode?.length === 0 || each?.countryCode === null) {
						contactErrorList.push(`${each.fieldName} must have a Country Code`);
					}
					if (each?.contactNumber?.length === 0) {
						contactErrorList.push(`${each.fieldName} must have a Contact Number`);
					}
					if (each?.contactNumber?.length > 0 && !isValidPhoneNumber(`+${each?.countryCode}${each?.contactNumber}`)) {
						contactErrorList.push(`${each.fieldName} must have a valid Contact Number`);
					}
				}
			}
		});

		if (errorList?.length > 0 && selectedTaskType === "Default") {
			setImageOrFileError(`Atleast 1 attachment is required in ${errorList[0]}`);
		} else {
			setImageOrFileError("");
		}

		if (contactErrorList?.length > 0) {
			setCustomFieldErrors(contactErrorList);
		} else {
			setCustomFieldErrors([]);
		}
	}, [fieldsData, missingEntities]);

	return (
		<>
			{missingEntities.map((item) =>
				item.isDefault !== 1 ? getInputFields(item, fieldsData.customFieldsComplex) : null
			)}
		</>
	);
};

export default CustomFieldsComplex;

const Marker = ({ lat, lng }: any) => <div className="wrapper"></div>;
