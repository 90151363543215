import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
//@ts-ignore
import Supercluster from "supercluster";

export default class IconClusterLayer extends CompositeLayer {
	//@ts-ignore
	shouldUpdateState({ changeFlags }) {
		return changeFlags.somethingChanged;
	}
	//@ts-ignore
	updateState({ props, oldProps, changeFlags }) {
		const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

		if (rebuildIndex) {
			const index = new Supercluster({
				maxZoom: 16,
				radius: props.sizeScale * Math.sqrt(2),
			});
			index.load(
				// @ts-ignore Supercluster expects proper GeoJSON feature
				props.data.map((d) => ({
					geometry: { coordinates: props.getPosition(d) },
					properties: d,
				}))
			);
			this.setState({ index });
		}

		const z = Math.floor(this.context.viewport.zoom);
		if (rebuildIndex || z !== this.state.z) {
			//@ts-ignore
			this.setState({
				//@ts-ignore
				data: this.state.index.getClusters([-180, -85, 180, 85], z),
				z,
			});
		}
	}
	//@ts-ignore
	getPickingInfo(params) {
		const { info, mode } = params;
		const pickedObject = info.object?.properties;
		if (pickedObject) {
			let objects;
			if (pickedObject.cluster && mode !== "hover") {
				//@ts-ignore
				objects = this.state.index.getLeaves(pickedObject.cluster_id, 25).map((f) => f.properties);

				//@ts-ignore
				this.props.onClick({ info, cluster: true });
			} else if (mode !== "hover" && !pickedObject.cluster) {
				//@ts-ignore
				this.props.onClick({ info: pickedObject, cluster: false });
			}
			return { ...info, object: pickedObject, objects };
		}
		return { ...info, object: undefined };
	}

	renderLayers() {
		const { data } = this.state;
		const {
			//@ts-ignore
			sizeScale,
		} = this.props;

		return [
			new IconLayer(
				{
					//@ts-ignore
					data,
					iconAtlas: "/images/Frame25.png",
					iconMapping: {
						marker1: {
							x: 358,
							y: 0,
							width: 184,
							height: 204,
						},
						//marker for the cluster
						marker: {
							x: 0,
							y: 0,
							width: 184,
							height: 204,
						},
						pointSite: {
							x: 185,
							y: 0,
							width: 184,
							height: 204,
						},
						geoFanceName: {
							x: 0,
							y: 0,
							width: 50,
							height: 50,
						},
					},
					sizeScale,
					getPosition: (d) => d.geometry.coordinates,
					getIcon: (d) =>
						d.properties.cluster
							? "marker"
							: d.properties.employeeID === -2
							? d.properties.polyline
								? "pointSite"
								: "pointSite"
							: "marker1",
				},
				this.getSubLayerProps({
					id: "icon",
				})
			),
		];
	}
}
