//react
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//query
import { useQuery } from "@apollo/client";
//library
import { FlyToInterpolator } from "@deck.gl/core";
//types, utils, components
import {
	CLIENTS_DATA,
	ENTITY_METADATA,
	//  PARTIAL_CLIENTS_DATA
} from "../../../@types";
import { deleteSites } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { GET_ALL_CLIENTS } from "../../../schema";
// import { mapOptions, mapStyles } from "../../../utils";
import ClientDetailedSidebarView from "./ClientDetailedSidebarView";
import ClientList from "./ClientList";
import DeckGlClientMap from "../DeckGL/DeckGlClientMap";
//mui
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { CircularProgress } from "@mui/material";

type Props = {
	clients: CLIENTS_DATA[];
	loading: boolean;
	setVariables: Dispatch<SetStateAction<any>>;
	setClients: Dispatch<SetStateAction<CLIENTS_DATA[]>>;
	entityMetadata: ENTITY_METADATA[];
	filterCount: number;
};
type allClients = {
	point: CLIENTS_DATA[];
	geo?: CLIENTS_DATA[];
};
type ViewState = {
	latitude: number;
	longitude: number;
	zoom: number;
	minZoom: number;
	transitionInterpolator?: FlyToInterpolator;
	transitionDuration?: number | "auto";
};

const ClientMapView: FC<Props> = ({ clients, loading, setVariables, setClients, entityMetadata, filterCount }) => {
	const [expanded, setExpanded] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState<null | string>(null);
	const { loading: loadingClients, data: dataClients } = useQuery(GET_ALL_CLIENTS);
	const [allClients, setAllClients] = useState<allClients>();
	const isLoading = loadingClients || loading;
	const [isDeletingClient, setIsDeletingClient] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [initialViewState, setInitialViewState] = useState<ViewState>({
		latitude: 20.5937,
		longitude: 78.9629,
		zoom: 1,
		minZoom: 3,
	});
	const [circleCord, setCircleCord] = useState({ show: false, lat: 0, lng: 0 });
	const [stateChange, setStateChange] = useState(false);
	useEffect(() => {
		setCircleCord({ show: false, lat: 0, lng: 0 });
		setInitialViewState({
			latitude: 20.5937,
			longitude: 78.9629,
			zoom: 1,
			minZoom: 3,
		});
	}, [filterCount, isDeletingClient]);
	const flyToCity = useCallback(
		(e: any) => {
			if (e.cluster) {
				const evt = e.info;
				const latitude = evt.coordinate[1];
				const longitude = evt.coordinate[0];
				const zoom = evt.viewport.zoom + 1.8;
				const minZoom = 3;
				setInitialViewState({
					latitude: latitude,
					longitude: longitude,
					zoom,
					minZoom,
					transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
					transitionDuration: "auto",
				});
				setStateChange(false);
			} else if (e.info) {
				const evt = e.info;
				const latitude = evt.coordinates[1];
				const longitude = evt.coordinates[0];
				const zoom = 16;
				const minZoom = 3;
				setInitialViewState({
					latitude: latitude,
					longitude: longitude,
					zoom,
					minZoom,
					transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
					transitionDuration: "auto",
				});
				setStateChange(false);
			}
		},
		[stateChange]
	);

	useEffect(() => {
		if (!isLoading && dataClients) {
			const clientsData = dataClients?.get_clients_by_adminID.map((ele: any) => {
				const newObj = {
					name: ele.clientName,
					coordinates: [ele.lng, ele.lat],
					position: [ele.lng, ele.lat],
					lat: ele.lat,
					lng: ele.lng,
					key: ele.clientID,
					...ele,
				};
				return newObj;
			});
			setAllClients({ point: clientsData });
		}
	}, [dataClients, isLoading]);

	useEffect(() => {
		// reset selected client id to null on filterCount change
		if (!loading && clients) {
			setSelectedClientId(null);
		}
	}, [clients, loading]);

	const selectedClient = clients?.find((client) => client.clientID === selectedClientId);

	const deleteHandler = async (id: string) => {
		if (!id || id.length === 0) return;
		setIsDeletingClient(true);
		const { success, message } = await deleteSites([id]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsDeletingClient(false);
		if (success) {
			setSelectedClientId(null);
			setClients(clients.filter((client) => client.clientID !== id));
		}
	};
	const FilterClients = (allClients: CLIENTS_DATA[], clients: CLIENTS_DATA[], filterCount: number) => {
		if (filterCount > 0) {
			const clientIds = clients.map((client) => client.clientID); // Get an array of clientIds from clients
			return allClients?.filter((client) => clientIds.includes(client.clientID)); // Filter based on clientId
		} else if (allClients.length !== clients.length && filterCount === 0 && clients.length !== 0) {
			const clientIds = clients.map((client) => client.clientID); // Get an array of clientIds from clients
			return allClients?.filter((client) => clientIds.includes(client.clientID));
		} else {
			return allClients;
		}
	};
	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || loadingClients || loading || !allClients)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<div className="sub_header">
				{expanded ? (
					<>
						Clients List
						<button className="for_mobile_view" onClick={() => setExpanded(false)}>
							<CloseIcon />
						</button>
					</>
				) : (
					<button className="for_mobile_view" onClick={() => setExpanded(true)}>
						<MenuIcon />
					</button>
				)}
			</div>
			<div className="datagrid-table">
				{allClients && (
					<DeckGlClientMap
						AllClients={{ point: FilterClients(allClients.point, clients, filterCount) }}
						setExpanded={setExpanded}
						setSelectedClientId={setSelectedClientId}
						initialViewState={initialViewState}
						setInitialViewState={setInitialViewState}
						flyToCity={flyToCity}
						circleCord={circleCord}
						setCircleCord={setCircleCord}
					/>
				)}
				{!selectedClientId ? (
					<ClientList
						setSelectedClientId={setSelectedClientId}
						clients={clients}
						setVariables={setVariables}
						flyToCity={flyToCity}
						isLoading={isLoading}
						expanded={expanded}
						setCircleCord={setCircleCord}
					/>
				) : (
					<ClientDetailedSidebarView
						selectedClientId={selectedClientId}
						setSelectedClientId={setSelectedClientId}
						selectedClient={selectedClient}
						deleteHandler={deleteHandler}
						expanded={expanded}
						isDeletingClient={isDeletingClient}
						entityMetadata={entityMetadata}
						setCircleCord={setCircleCord}
					/>
				)}
			</div>
		</>
	);
};

export default ClientMapView;
