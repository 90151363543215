import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { CircularProgress } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { SITES_DATA } from "../../../@types";
import { deleteSites } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import SiteDetailedSidebarView from "./SiteDetailedSidebarView";
import SitesList from "./SitesList";
import DeckGlClientMap from "../DeckGL/DeckGlClientMap";
import { FlyToInterpolator } from "@deck.gl/core";
import polylineDecoder from "../DeckGL/polylineDecoder";

type Props = {
	sites: SITES_DATA[];
	loading: boolean;
	setSites: Dispatch<SetStateAction<SITES_DATA[]>>;
};
type ViewState = {
	latitude: number;
	longitude: number;
	zoom: number;
	minZoom: number;
	transitionInterpolator?: FlyToInterpolator;
	transitionDuration?: number | "auto";
};

const SitesMap: FC<Props> = ({ sites, loading, setSites }) => {
	const [expanded, setExpanded] = useState(false);
	const [selectedSiteId, setSelectedSiteId] = useState<null | string>(null);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isDeleting, setIsDeleting] = useState(false);
	const selectedSite = sites.find((site) => site.clientID === selectedSiteId);

	const [initialViewState, setInitialViewState] = useState<ViewState>({
		latitude: 20.5937,
		longitude: 78.9629,
		zoom: 1,
		minZoom: 3,
	});
	const mapSiteToObj = (site: any) => ({
		name: site.clientName,
		coordinates: [site.longitude, site.latitude],
		position: [site.longitude, site.latitude],
		lat: site.latitude,
		lng: site.longitude,
		key: site.clientID,
		...site,
	});
	const [allsites] = useState(() => {
		const point = sites.filter((site) => !site?.polyline).map(mapSiteToObj);
		const geo = sites
			.filter((site) => site?.polyline)
			.map(mapSiteToObj)
			?.map((oneGeo) => ({
				...oneGeo,
				lat: polylineDecoder(oneGeo.polyline).center.lat,
				lng: polylineDecoder(oneGeo.polyline).center.lng,
				coordinates: [polylineDecoder(oneGeo.polyline).center.lng, polylineDecoder(oneGeo.polyline).center.lat],
				latitude: polylineDecoder(oneGeo.polyline).center.lat,
				longitude: polylineDecoder(oneGeo.polyline).center.lng,
			}));

		return { point, geo };
	});
	const [circleCord] = useState({
		show: true,
		point: sites.filter((site) => !site?.polyline).map(mapSiteToObj),
	});

	const flyToCity = useCallback((e: any) => {
		if (e.cluster) {
			const evt = e.info;
			const latitude = evt.coordinate[1];
			const longitude = evt.coordinate[0];
			const zoom = evt.viewport.zoom + 1.8;
			const minZoom = 3;
			setInitialViewState({
				latitude: latitude,
				longitude: longitude,
				zoom,
				minZoom,
				transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
				transitionDuration: "auto",
			});
		} else if (e.info) {
			const evt = e.info;
			const latitude = evt.coordinates[1];
			const longitude = evt.coordinates[0];
			const zoom = 16;
			const minZoom = 3;
			setInitialViewState({
				latitude: latitude,
				longitude: longitude,
				zoom,
				minZoom,
				transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
				transitionDuration: "auto",
			});
		}
	}, []);

	const deleteHandler = async (id: string) => {
		if (!id || id.length === 0) return;
		setIsDeleting(true);
		const { success, message } = await deleteSites([id]);
		setIsDeleting(false);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setSites(sites.filter((site) => site.clientID !== id));
	};
	console.log(allsites, "sites");
	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || loading)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<div className="sub_header">
				{expanded ? (
					<>
						Sites List
						<button className="for_mobile_view" onClick={() => setExpanded(false)}>
							<CloseIcon />
						</button>
					</>
				) : (
					<button className="for_mobile_view" onClick={() => setExpanded(true)}>
						<MenuIcon />
					</button>
				)}
			</div>
			<div className="datagrid-table">
				{sites && (
					<DeckGlClientMap
						AllClients={allsites}
						setExpanded={setExpanded}
						setSelectedClientId={setSelectedSiteId}
						initialViewState={initialViewState}
						setInitialViewState={setInitialViewState}
						flyToCity={flyToCity}
						circleCord={circleCord}
					/>
				)}
				{selectedSiteId === null || selectedSite === undefined ? (
					<SitesList setSelectedSiteId={setSelectedSiteId} sites={sites} expanded={expanded} flyToCity={flyToCity} />
				) : (
					<SiteDetailedSidebarView
						setSelectedSiteId={setSelectedSiteId}
						selectedSite={selectedSite}
						isDeleting={isDeleting}
						expanded={expanded}
						deleteHandler={deleteHandler}
					/>
				)}
			</div>
		</>
	);
};

export default SitesMap;
