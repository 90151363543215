/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { SITES_DATA } from "../../../@types";
import polylineDecoder from "../DeckGL/polylineDecoder";

type Props = {
	sites: SITES_DATA[];
	expanded: boolean;
	setSelectedSiteId: Dispatch<SetStateAction<string | null>>;
	flyToCity: (e: any) => void;
};
const SitesList: FC<Props> = ({ sites, setSelectedSiteId, expanded, flyToCity }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const filteredList = sites.filter((site) =>
		site.clientName?.replace(/ /g, "").toLocaleLowerCase()?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase())
	);
	const list = filteredList.length > 0 ? filteredList : sites;
	return (
		<div className={expanded ? "user_add client_sidebar open" : "user_add client_sidebar close"}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<input
					type="search"
					placeholder="Search Here.."
					className="search"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</div>

			<ul>
				{list.map((site) => (
					<li
						style={{ gridTemplateColumns: "1fr" }}
						key={site.clientID}
						onClick={() => {
							setSelectedSiteId(site.clientID);
							// onSiteClicked(site.clientID);
							// setSelectedClientId(client.clientID);
							const newObj = site.polyline
								? {
										...site,
										lat: polylineDecoder(site.polyline).center.lat,
										lng: polylineDecoder(site.polyline).center.lng,
										coordinates: [polylineDecoder(site.polyline).center.lng, polylineDecoder(site.polyline).center.lat],
										latitude: polylineDecoder(site.polyline).center.lat,
										longitude: polylineDecoder(site.polyline).center.lng,
								  }
								: {
										name: site.clientName,
										coordinates: [site.longitude, site.latitude],
										position: [site.longitude, site.latitude],
										key: site.clientID,
										...site,
								  };
							flyToCity({ info: newObj, cluster: false });
						}}>
						<div>
							<h3>{site.clientName}</h3>
							<small>
								<AddLocationAltOutlinedIcon /> {site.address}
							</small>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SitesList;
