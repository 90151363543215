import React, { ReactNode } from "react";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";

interface ErrorBoundaryProps {
	children: ReactNode;
	fallback?: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		// Define the initial state
		this.state = { hasError: false };
	}

	// This lifecycle method is used to update the state when an error occurs
	// eslint-disable-next-line no-unused-vars
	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		// Return new state indicating there's an error
		console.log(Error, "error");
		return { hasError: true };
	}

	// This lifecycle method is used for side effects, like logging
	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		// Log the error or send it to an external error tracking service
		console.log({ error, errorInfo });
	}

	render() {
		// Check if an error occurred and display a fallback UI
		if (this.state.hasError) {
			if (this.props.fallback) {
				return this.props.fallback; // Render custom fallback if provided
			}

			return (
				<div className="  flex flex-col items-center  justify-center h-[90%] font-regular text-center">
					<ErrorOutlineTwoToneIcon sx={{ fontSize: "5rem" }} className="text-gray-800" />
					<h2 className="text-[1rem] md:text-[3rem] font-regular pt-3">Oops! Something went wrong.</h2>
					<p className="text-gray-500 p-2">We’re working to fix the issue. Please try refreshing the page.</p>
					<button
						className={`bg-[#1976d2] py-2  px-3 rounded-md text-white my-2`}
						onClick={() => {
							window.location.reload();
							this.setState({ hasError: false });
						}}>
						Reload Page
					</button>
				</div>
			);
		}

		// Render children if no error has occurred
		return this.props.children;
	}
}

export default ErrorBoundary;
