import { CompositeLayer } from "@deck.gl/core";
import { TextLayer } from "@deck.gl/layers";
//@ts-ignore
import Supercluster from "supercluster";

export default class TextClusterLayer extends CompositeLayer {
	//@ts-ignore
	shouldUpdateState({ changeFlags }) {
		return changeFlags.somethingChanged;
	}
	//@ts-ignore
	updateState({
		//@ts-ignore
		props,
		//@ts-ignore
		changeFlags,
	}) {
		const rebuildIndex = changeFlags.dataChanged;

		if (rebuildIndex) {
			const index = new Supercluster({
				maxZoom: 16,
				radius: props.sizeScale * Math.sqrt(2),
			});
			index.load(
				// @ts-ignore Supercluster expects proper GeoJSON feature
				props.data.map((d) => ({
					geometry: { coordinates: props.getPosition(d) },
					properties: d,
				}))
			);
			this.setState({ index });
		}

		const z = Math.floor(this.context.viewport.zoom);
		if (rebuildIndex || z !== this.state.z) {
			this.setState({
				//@ts-ignore
				data: this.state.index.getClusters([-180, -85, 180, 85], z),
				z,
			});
		}
	}

	renderLayers() {
		const { data } = this.state;
		return new TextLayer({
			id: "labels",
			//@ts-ignore
			data: data,
			getText: (d) => {
				if (d.properties.cluster) return `${d.properties.point_count}`;
				else return `${d.properties.name}`;
			},
			getPosition: (d) => d.geometry.coordinates,
			getPixelOffset: (d) => {
				if (d.properties.cluster) return [2, 1];
				// else if (d.properties?.polyline) return [0, 0];
				else return [0, 20];
			},
			getSize: (d) => {
				if (d.properties.cluster) return 10;
				else return 14;
			},
			getColor: (d) => (d.properties.cluster ? [255, 255, 255] : [0, 0, 0]),
			fontWeight: 800,
			getTextAnchor: "middle",
		});
	}
}
