/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Dispatch, FC, useMemo, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import __debounce from "lodash/debounce";
import Avatar from "@mui/material/Avatar";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { CLIENTS_DATA } from "../../../@types";
import { findAvatarInitials } from "../../../utils";

const INCREASE_LIMIT = 20;

type Props = {
	clients: CLIENTS_DATA[];
	isLoading: boolean;
	setVariables: Dispatch<any>;
	setSelectedClientId: Dispatch<any>;
	expanded: boolean;
	flyToCity: any;
	setCircleCord: any;
};

const ClientList: FC<Props> = ({
	clients,
	setVariables,
	setSelectedClientId,
	isLoading,
	flyToCity,
	expanded,
	setCircleCord,
}) => {
	// Handle row click
	const handleRowClick = useCallback(
		(client: CLIENTS_DATA) => {
			setSelectedClientId(client.clientID);
			const newObj = {
				name: client.clientName,
				coordinates: [client.lng, client.lat],
				position: [client.lng, client.lat],
				key: client.clientID,
				...client,
			};
			flyToCity({ info: newObj, cluster: false });
			setCircleCord({ show: true, point: [newObj] });
		},
		[setSelectedClientId, flyToCity, setCircleCord]
	);

	// Debounce search input
	const debouncedResults = useMemo(
		() =>
			__debounce((e) => {
				setVariables((variables: any) => ({
					...variables,
					searchKey: e.target.value,
				}));
			}, 500),
		[setVariables]
	);

	// Load more rows when reaching the end
	const loadMoreRows = useCallback(() => {
		setVariables((variables: any) => ({
			...variables,
			take: variables.take + INCREASE_LIMIT,
		}));
	}, [setVariables]);

	return (
		<div className={expanded ? "user_add client_sidebar open" : "user_add client_sidebar close"}>
			{/* Search Input */}
			<input type="search" placeholder="Search Here.." className="search" onChange={debouncedResults} />
			{/* DataGrid with single column for custom rendering */}
			<div className="flex h-[90%] w-full">
				<DataGrid
					rows={clients}
					columns={[
						{
							field: "clientDetails",
							headerName: "", // No header for the single column
							flex: 1,
							sortable: false,

							renderCell: (params) => {
								const client = params.row;

								return (
									// eslint-disable-next-line jsx-a11y/click-events-have-key-events
									<li
										style={{
											display: "flex",
											alignItems: "center",
											cursor: "pointer",
											padding: "10px",
											borderBottom: "1px solid #ddd",
											width: "100%",
										}}
										onClick={() => handleRowClick(client)}>
										<picture>
											<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4" }}>
												{findAvatarInitials(client.clientName)}
											</Avatar>
										</picture>
										<div style={{ marginLeft: "10px" }}>
											<h3 style={{ margin: 0 }}>{client.clientName}</h3>
											<small>{client.phoneNumber}</small>
										</div>
										<AddLocationAltOutlinedIcon style={{ marginLeft: "auto", color: "#25A8F4" }} />
									</li>
								);
							},
						},
					]}
					getRowId={(row) => row.clientID}
					pageSize={INCREASE_LIMIT}
					rowsPerPageOptions={[INCREASE_LIMIT]}
					loading={isLoading}
					disableSelectionOnClick
					hideFooter // Hide the default footer for cleaner UI
					hideFooterPagination
					headerHeight={0}
					// @ts-ignore
					onRowsScrollEnd={loadMoreRows}
					paginationMode="server"
					sx={{
						"& .MuiDataGrid-virtualScroller": {
							marginTop: "0 !important",
						},
						"& .MuiDataGrid-columnHeaders": {
							display: "none",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default ClientList;
