/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// react
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from "react";
// apollo
import { useMutation, useQuery } from "@apollo/client";
// hooks
import useAdminInfo from "../../useAdminInfo";
import useFetch from "../../useFetch";
// schema
import { ALL_ROUTES_QUERY, DELETE_TASK, GET_USERS, UPSERT_TASK } from "../../../schema";
import { GET_CLIENTS_BY_EMPLOYEE_ID } from "../../../schema/sites_clients";
import { GET_All_CUSTOM_TASK_BY_EMPLOYEE } from "../../../schema/tasks";
import {
	GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY,
	GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK,
} from "../../../schema/entityMetadata";
// redux
import { APP_DISPATCH, showNotification } from "../../../redux";
import { useDispatch } from "react-redux";
// types
import {
	ADDRESS_DETAIL,
	ENTITY_METADATA,
	EXECUTIVE_USER,
	PARTIAL_CLIENTS_DATA,
	ROUTE_DATA,
	SITES_DATA,
	TASK_TYPE,
} from "../../../@types";
import { ADMIN_ID, DATE_TYPE_FORMAT } from "../../../constants";
// mui
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// libraries
import GoogleMapReact from "google-map-react";
import moment from "moment";
import Link from "next/link";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import ReactSelect from "react-select";
// utils, components
import { mapStyles, validateCustomTasks, getSiteType } from "../../../utils";
import { validateTasks } from "../../../utils";
import SearchBox from "./SearchBox";
import CustomFieldsComplex from "../../common/CustomFieldsComplex";
import PrimaryDatePicker from "../../common/PrimaryDatePicker";
import { useAdminCompanySettings } from "../../useAdminEntitlements";

type SELECT_TASK_TYPE = "sites" | "clients" | "tasks" | "routes";

const findTaskLabel = (taskType: SELECT_TASK_TYPE, id: string, sites: any, clients: any, routes: any) => {
	if (!id || !sites || !clients || !routes) return "";
	if (taskType === "sites") {
		const site: SITES_DATA = sites.find((s: SITES_DATA) => s.clientID === id);
		return site ? site.clientName : "";
	}
	if (taskType === "clients") {
		const client = clients.find((c: PARTIAL_CLIENTS_DATA) => c.clientID === id);
		return client ? client.clientName : "";
	}
	if (taskType === "routes") {
		const route = routes.find((r: ROUTE_DATA) => r.routeID === id);
		return route ? route.name : "";
	}
};

const NEW_FORM_DATA: TASK_TYPE = {
	taskID: uuidv4(),
	clientID: "",
	employeeID: 112334, //change this immediately
	date: moment().format("DD-MM-YYYY"), //change this for passed date
	adminAssigned: 1, //if created form panel
	checkinTime: null,
	checkoutTime: null,
	lat: null,
	lon: null,
	taskDescription: null,
	startTime: null,
	endTime: null,
	address: null,
	timestamp: moment().valueOf(),
	lastModifiedByAdminID: typeof localStorage !== "undefined" ? +localStorage.getItem(ADMIN_ID)! : null,
	lastUpdatedAdminID: null,
	inPool: null,
	createdByAdminID: typeof localStorage !== "undefined" ? +localStorage.getItem(ADMIN_ID)! : null,
	createdByEmpID: null,
	createdBySourceID: null,
	createdTs: moment().valueOf(),
	lastModifiedByEmpID: null,
	lastModifiedBySourceID: null,
	lastModifiedTs: null,
	customFieldsComplex: null,
	clientInfo: null,
	repeatedTaskMetadata: null,
	rescheduleDate: null,
};

const mapOptions = {
	fullscreenControl: false,
	mapTypeControl: false,
	streetViewControl: true,
	styles: [],
};

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	taskID: null | string;
	setTasks: Dispatch<SetStateAction<TASK_TYPE[] | null>>;
	tasks: TASK_TYPE[];
	fieldExecutives: Pick<EXECUTIVE_USER, "firstName" | "lastName" | "employeeID">[];
	employeeID: number;
	date: string; //selected date from filter,
	addressDetail: ADDRESS_DETAIL | {};
	refetch: () => void;
	isClientAddressMasked: any;
};

const TaskFormSidebar: FC<Props> = ({
	setExpanded,
	taskID,
	tasks,
	fieldExecutives,
	employeeID,
	date,
	setTasks,
	addressDetail,
	refetch,
	isClientAddressMasked,
}) => {
	// variables
	const { adminID } = useAdminInfo();
	const executive = fieldExecutives?.find((exec) => exec.employeeID === employeeID);
	const { data: employeesList, loading: loadingEmployeesList } = useQuery(GET_USERS);
	const UPDATED_FORM_DATA: TASK_TYPE = {
		...NEW_FORM_DATA,
		taskID: uuidv4(),
		date: date,
		employeeID: executive?.employeeID!,
		createdByAdminID: adminID,
		lastModifiedByAdminID: adminID,
		createdTs: moment().valueOf(),
		lastModifiedTs: moment().valueOf(),
		...addressDetail,
	};
	const currentTask = tasks.find((task) => task.taskID === taskID);

	// states
	const [taskTypeList, setTaskTypeList] = useState<any[]>([]);
	const [selectedTaskType, setSelectedTaskType] = useState<any>("Default");
	const [employees, setEmployees] = useState<any[]>([]);

	const [formData, setFormData] = useState<TASK_TYPE>(
		currentTask
			? {
					...currentTask,
					rescheduleDate: dayjs(currentTask.date, "YYYY-MM-DD"),
					repeatedTaskMetadata: currentTask?.repeatedTaskMetadata
						? { ...currentTask?.repeatedTaskMetadata, reassignEmpForAllRepeatedTasks: false }
						: null,
			  }
			: UPDATED_FORM_DATA
	);
	const [originalEmployeeID] = useState(currentTask ? currentTask.employeeID : null);

	const [startTime, setStartTime] = useState<number | null>(currentTask ? currentTask?.startTime : null);
	const [endTime, setEndTime] = useState<number | null>(currentTask ? currentTask?.endTime : null);

	const [isSaving, setIsSaving] = useState(false);
	const [zoom, setZoom] = useState(17);
	const [errors, setErrors] = useState<string[]>([]);
	const [imageOrFileError, setImageOrFileError] = useState("");
	const [customFieldErrors, setCustomFieldErrors] = useState<any[]>([]);
	const [missingEntities, setMissingEntities] = useState<ENTITY_METADATA[]>([]);
	const [missingCustomEntities, setMissingCustomEntities] = useState<ENTITY_METADATA[]>([]);
	const [customTaskEditPhase, setCustomTaskEditPhase] = useState<boolean>(false);
	const [jobType, setJobType] = useState<any>(null);
	const [deleteFutureTask, setDeleteFutureTask] = useState(false);
	const [jobTypeUpdated, setJobTypeUpdated] = useState(false);
	const [center, setCenter] = useState({
		lat: currentTask?.lat || 22.593684,
		lng: currentTask?.lon || 81.96288,
	});

	// redux
	const dispatch = useDispatch<APP_DISPATCH>();

	// queries and mutations
	const [upsertTask] = useMutation(UPSERT_TASK);
	const [deleteTask] = useMutation(DELETE_TASK);
	const { data: dataSites, loading: loadingSites } = useFetch<any>("/sites");
	const { data: dataRoutes } = useQuery(ALL_ROUTES_QUERY);
	const { data: dataSiteTypes, loading: loadingSiteTypes } = useFetch<any>("/clients/siteTypes");

	//todo to be removed after the Custom Task is migrated
	const {
		data: entityMetaData,
		loading: loadingEntity,
		refetch: refetchEntity,
	} = useQuery(GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY, {
		variables: { entity: "Tasks" },
		skip: selectedTaskType !== "Default",
	});

	// for new custom task type
	const { data: customEntityMetaData, loading: loadingCustomEntity } = useQuery(
		GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK,
		{
			variables: { customEntityID: selectedTaskType },
			skip: selectedTaskType === "Default" || !selectedTaskType,
		}
	);

	const { data: dataClients } = useQuery(GET_CLIENTS_BY_EMPLOYEE_ID, {
		variables: {
			employeeID: employeeID,
		},
	});

	const customTaskVariable = {
		employeeID: employeeID,
	};

	if (currentTask !== undefined) {
		customTaskVariable["filters"] = [{ column: "ACTIVE", columnValue: "all", comparisonOperator: "EQ" }];
	}

	const { data: allCustomTaskData, loading: taskTypeListLoading } = useQuery(GET_All_CUSTOM_TASK_BY_EMPLOYEE, {
		fetchPolicy: "network-only",
		variables: customTaskVariable,
	});

	// variables
	const sites = dataSites?.sites || [];
	const siteTypes = dataSiteTypes?.siteTypes || [];
	const clients = dataClients?.get_clients_by_employeeID || [];
	const validClientIDs = clients?.map((client:any) => client?.clientID); //taking the clientID into account which are visible to the employee

	const routes = dataRoutes?.get_routes || [];
	const isRepeated = formData.repeatedTaskMetadata === null ? false : true;
	const type: SELECT_TASK_TYPE = formData.clientInfo
		? formData.clientInfo.employeeID === -2
			? "sites"
			: "clients"
		: "tasks";
	const [taskType, setTaskType] = useState<SELECT_TASK_TYPE>(taskID ? type : formData.address ? "tasks" : "clients");
	const labelReactSelect = findTaskLabel(taskType, formData.clientID!, sites, clients, routes);

	// * if checkin and checkout time is present then disable edit
	const disallowEdit = !!formData.checkinTime || !!formData.checkoutTime;
	const filterdSites = sites.filter(
		(d: any) => (jobType?.value || jobType?.value === 0) && d?.jobTypeID === jobType.value
	);

	// states for map
	const [mapApiLoaded, setMapApiLoaded] = useState(false);
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const [draggable, setDraggable] = useState(true);
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	// effects
	useEffect(() => {
		if (!loadingSites && !loadingSiteTypes && formData.clientInfo?.clientID && formData.clientInfo?.employeeID === -2) {
			setJobType(getSiteType(sites, siteTypes, formData.clientInfo?.clientID));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingSites, loadingSiteTypes]);
	useEffect(() => {
		if (jobTypeUpdated === true) {
			const temp = {
				clientID: "",
				clientName: "",
				employeeID: "",
			};
			setFormData((prev: any) => ({
				...prev,
				clientInfo: temp,
			}));
		}
		setJobTypeUpdated(false);
	}, [jobTypeUpdated]);

	//TODO to be removed after the Custom Task is migrated
	useEffect(() => {
		const updatedMissingEntities = entityMetaData?.get_all_entitymetadata_by_entity_for_current_company?.filter(
			(value: ENTITY_METADATA) => value.isMandatory === 0 && value.active === 1
		);
		if (updatedMissingEntities && updatedMissingEntities.length > 0) {
			setMissingEntities(updatedMissingEntities);
		}
	}, [loadingEntity, entityMetaData]);

	// new custom Task
	useEffect(() => {
		const updatedMissingEntities = customEntityMetaData?.get_all_entitymetadata_by_custom_entity?.filter(
			(value: ENTITY_METADATA) => value.isMandatory === 0 && value.active === 1
		);
		if (updatedMissingEntities && updatedMissingEntities.length > 0) {
			setMissingCustomEntities(updatedMissingEntities);
		}
	}, [loadingCustomEntity, customEntityMetaData]);

	useEffect(() => {
		if (allCustomTaskData?.get_custom_entities_by_employee) {
			setTaskTypeList(allCustomTaskData?.get_custom_entities_by_employee);
		}
	}, [allCustomTaskData]);
	useEffect(() => {
		const selectedCustomEntityID = formData?.customEntity?.customEntityID || null;
		setSelectedTaskType(selectedCustomEntityID || "Default");
		setCustomTaskEditPhase(selectedCustomEntityID ? true : false);
	}, [formData.customEntity?.customEntityID]);

	useEffect(() => {
		if (!loadingEmployeesList) {
			setEmployees(employeesList?.get_users_by_admin_id || []);
		}
	}, [employeesList, loadingEmployeesList]);

	//* functions
	const checkDateFormat = (date: string | null) => {
		if (date === null) return null;
		const isValidDayJsDate = dayjs.isDayjs(date);

		// Check if the date is already in the correct format
		if (dayjs(date, "DD-MM-YYYY", true).isValid()) {
			// if a dayjs object then convert into string
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		} else if (dayjs(date, "YYYY-MM-DD", true).isValid()) {
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		}
	};
	const changeJobType = (e: any) => {
		setJobType(e);
		setJobTypeUpdated(true);
	};

	function rescheduleTask(originalStartTime: any, originalEndTime: any, originalDate: any, newDate: any) {
		// Parse the new date
		const newDateObj = dayjs(newDate, "YYYY-MM-DD");
		const oriDateObj = dayjs(originalDate, "YYYY-MM-DD");

		// Calculate time difference between original start time and end time
		const timeDifference = originalEndTime - originalStartTime;

		// Calculate new start time by adding time difference to the new date
		const newStartTime = newDateObj.valueOf() + (originalStartTime - oriDateObj.valueOf());

		// Calculate new end time based on the new start time and original time difference
		const newEndTime = newStartTime + timeDifference;

		// Return the updated task details (you can adjust the return value as needed)
		return {
			newDate: newDateObj.format("YYYY-MM-DD"),
			newStartTime: newStartTime, // Convert back to formatted time if needed
			newEndTime: newEndTime, // Convert back to formatted time if needed
		};
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (imageOrFileError.length > 0) {
			dispatch(
				showNotification({
					message: imageOrFileError,
					severity: "error",
				})
			);
			return;
		}

		if (customFieldErrors.length > 0) {
			dispatch(
				showNotification({
					message: customFieldErrors[0],
					severity: "error",
				})
			);
			return;
		}

		setIsSaving(true);

		// this is an extra flag that we need to pass in validate task to check if the admin can edit the task after submit.
		const isAdminOverrideFieldPresent = missingCustomEntities?.some(
			(each) => Boolean(each.allowAdminToEditAfterSubmit) === true
		);

		const saveErrors =
			selectedTaskType === "Default"
				? validateTasks({ ...formData, startTime, endTime }, taskType)
				: validateCustomTasks({ ...formData, startTime, endTime }, taskType, isAdminOverrideFieldPresent);
		const toSendFormData = { ...formData };
		setErrors([...saveErrors]);
		if (saveErrors.length > 0) {
			setIsSaving(false);
			return;
		}

		const toSend = {
			fromDate: moment(toSendFormData.date).format(DATE_TYPE_FORMAT),
			input: {
				customEntityID: selectedTaskType === "Default" ? null : selectedTaskType,
				address: toSendFormData.address,
				adminAssigned: toSendFormData.adminAssigned,
				clientID: toSendFormData.clientID,
				createdTs: toSendFormData.createdTs,
				date: taskID
					? toSendFormData.rescheduleDate
						? dayjs(toSendFormData.rescheduleDate).format(DATE_TYPE_FORMAT)
						: toSendFormData.date
					: toSendFormData.date,
				employeeID: toSendFormData.employeeID,
				endTime: endTime,
				lastModifiedTs: moment().valueOf(),
				lat: toSendFormData.lat,
				lon: toSendFormData.lon,
				startTime: startTime,
				taskDescription: toSendFormData.taskDescription,
				taskID: toSendFormData.taskID,
				timestamp: toSendFormData.timestamp,
				customFieldsComplex: toSendFormData.customFieldsComplex
					? toSendFormData.customFieldsComplex.map((field) => {
							const currentEntity = (selectedTaskType === "Default" ? missingEntities : missingCustomEntities).find(
								(each) => each.fieldID === field?.fieldID
							);
							return {
								fieldID: field?.fieldID,
								fieldValue: currentEntity?.dataType === "date" ? checkDateFormat(field.fieldValue) : field?.fieldValue,
								fieldValue2: field.fieldValue2,
								fieldValue3: field.fieldValue3,
								selectedOptions: field.selectedOptions,
								selectedFieldValues: field.selectedFieldValues,
							};
					  })
					: null,
			},
		};

		// overrite date, fromDate, startTime and endTime based on the reschedule date
		if (toSendFormData.rescheduleDate) {
			const rescheduledTaskData = rescheduleTask(
				startTime,
				endTime,
				toSendFormData.date,
				dayjs(toSendFormData.rescheduleDate).format(DATE_TYPE_FORMAT)
			);

			toSend.fromDate = rescheduledTaskData.newDate;
			toSend.input.date = rescheduledTaskData.newDate;
			toSend.input.startTime = rescheduledTaskData.newStartTime;
			toSend.input.endTime = rescheduledTaskData.newEndTime;
		}

		if (taskType === "routes" || toSendFormData?.routeID) {
			toSend.input["routeID"] = toSendFormData.routeID || null;
		}

		if (toSendFormData && toSendFormData.repeatedTaskMetadata) {
			delete toSendFormData.repeatedTaskMetadata.repeatedTaskID;
			toSend.input["repeatedTaskMetadata"] = toSendFormData.repeatedTaskMetadata;
		}

		const response = await upsertTask({ variables: toSend });
		if (response.data === null || (response?.errors && response.errors?.length > 0)) {
			dispatch(showNotification({ message: response?.errors && response.errors[0]?.message, severity: "error" }));
			setIsSaving(false);
			return;
		}

		dispatch(showNotification({ message: "Successfully assigned Task", severity: "success" }));
		if (!taskID) {
			setTasks([...tasks, ...response.data.upsert_task?.data.filter((task: TASK_TYPE) => task.date === date)]);
		} else {
			setTasks([
				...tasks.filter((task) => task.taskID !== formData.taskID),
				...response.data.upsert_task?.data.filter((task: TASK_TYPE) => task.date === date),
			]);
		}
		setIsSaving(false);
		setExpanded(false);
		refetch();
	};
	const handleDelete = async (deleteFutureTask: boolean) => {
		setIsSaving(true);
		await deleteTask({
			variables: {
				taskID: formData.taskID,
				repeatedTaskID: deleteFutureTask ? formData?.repeatedTaskMetadata?.repeatedTaskID : null,
				date: formData.date,
			},
		});
		dispatch(showNotification({ message: "Successfully deleted Task", severity: "success" }));
		setTasks([...tasks.filter((task) => task.taskID !== formData.taskID)]);
		setIsSaving(false);
		setExpanded(false);
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (!name || name.length === 0) return;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};
	const checkRepeatedTaskMetadata = (e: any) => {
		const { checked } = e.target;
		if (!checked) {
			setFormData((prevState) => ({ ...prevState, repeatedTaskMetadata: null }));
			return;
		}
		setFormData((prevState) => ({
			...prevState,
			repeatedTaskMetadata: {
				repeatedTaskID: uuidv4(), //uuid
				startDate: formData.date, //same as date above
				endDate: date, //required is repeat task != null// by default today
				sunday: true,
				monday: true,
				tuesday: true,
				wednesday: true,
				thursday: true,
				friday: true,
				saturday: true,
				reassignEmpForAllRepeatedTasks: false,
			},
		}));
	};
	const changeRepeatingDays = (day: string) => {
		const newFormData = { ...formData };
		if (newFormData.repeatedTaskMetadata === null || !day || day.length === 0) return;
		// @ts-ignore
		newFormData.repeatedTaskMetadata[day] = !newFormData.repeatedTaskMetadata[day];
		setFormData(newFormData);
	};
	const changeDynamicSelection = (e: any) => {
		if (!e) {
			setFormData((prev) => ({
				...prev,
				clientID: null,
				lat: null,
				lon: null,
				address: null,
				clientInfo: null,
			}));
			return;
		}
		setFormData((prevFormData) => {
			const updatedData = {
				...prevFormData,
				lat: e.lat,
				lon: e.lng,
				address: e.address,
			};
			if (taskType === "clients" || taskType === "sites") {
				delete updatedData.routeID;
				updatedData.clientID = e.value;
				updatedData.clientInfo = {
					employeeID: taskType === "clients" ? e.employeeID : -2,
					clientName: e.label,
					clientID: e.value,
				};
			}
			if (taskType === "routes") {
				updatedData.routeID = e.value;
				updatedData.clientInfo = null;
			} else if (taskType === "tasks") {
				delete updatedData.routeID;
				delete updatedData.clientID;
			}
			// Consolidated logic for customFieldsComplex (if needed)
			if (selectedTaskType !== "Default") {
				const missingCustomEntitiesIDs = missingCustomEntities.find((each) => each.dataType === "metaLocation");
				const fieldID = missingCustomEntitiesIDs?.fieldID;
				const field = updatedData.customFieldsComplex?.find((each) => each.fieldID === fieldID);
				if (field) {
					field.fieldValue = e.address;
				} else {
					const obj = [
						{
							fieldID: fieldID || "",
							fieldValue: e.address,
							fieldValue2: null,
							fieldValue3: null,
							selectedOptions: null,
							selectedFieldValues: null,
						},
					];
					if (updatedData.customFieldsComplex) {
						updatedData.customFieldsComplex = [...updatedData.customFieldsComplex, ...obj];
					} else {
						updatedData.customFieldsComplex = obj;
					}
				}
			}

			return updatedData;
		});
	};
	const generateAddress = (lat: any, lng: any, maps: any) => {
		if (!maps) return;
		const geocoder = new maps.Geocoder();
		geocoder.geocode({ location: { lat: lat, lng: lng } }, (results: any, status: string) => {
			if (status === "OK") {
				if (results[0]) {
					changeDynamicSelection({ lat: lat, lng: lng, address: results[0].formatted_address });
				} else {
					console.log("No results found");
				}
			} else {
				console.log("Geocoder failed due to: " + status);
			}
		});
	};
	const apiHasLoaded = (map: any, maps: any) => {
		setMapApiLoaded(true);
		setMap(map);
		setMaps(maps);
		map.setOptions({ styles: mapStyles });
		generateAddress(formData.lat || 22.593684, formData.lon || 81.96288, maps);
	};
	const onPlacesChanged = (place: any) => {
		generateAddress(place.geometry.location.lat(), place.geometry.location.lng(), maps);
	};
	const onMapChanges = (newCenter: any, newZoom: any) => {
		setCenter(newCenter);
		setZoom(newZoom);
	};
	const onMarkerInteraction = (_childKey: any, _childProps: any, mouse: any) => {
		if (disallowEdit) return;
		setDraggable(false);
		changeDynamicSelection({ lat: mouse.lat, lng: mouse.lng, address: formData.address });
	};
	const onMarkerInteractionMouseUp = (_childKey: any, _childProps: any, mouse: any) => {
		if (disallowEdit) return;
		setDraggable(true);
		generateAddress(mouse.lat, mouse.lng, maps);
	};
	const changeTaskType = (value: SELECT_TASK_TYPE) => {
		setTaskType(value);
		setFormData((prevState) => ({ ...prevState, clientInfo: null }));
	};
	const customFormatOptionLabel = (option: any) => (
		<div>
			<div
				style={{
					paddingTop: "5px",
					color: "#42444a",
					fontSize: "14px",
					fontWeight: 400,
				}}>
				{option.label.charAt(0).toUpperCase() + option.label.slice(1)}
			</div>
			<p
				style={{
					paddingTop: "5px",
					color: "gray",
					fontSize: "13px",
					lineHeight: "20px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}>
				{isClientAddressMasked === 1 && option?.address
					? "**** *** **"
					: option?.address?.length > 80
					? option?.address?.slice(0, 80) + "..."
					: option.address}
			</p>
		</div>
	);
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");
	// o - default
	// 1 - custom and default
	// 2 - custom
	useEffect(() => {
		if (customTaskFlag === 2) {
			if (taskTypeList?.length > 0 && selectedTaskType === "Default") {
				setSelectedTaskType(taskTypeList[0].customEntityID);
			}
		}
	}, [customTaskFlag, selectedTaskType, taskTypeList]);

	useEffect(() => {
		if (selectedTaskType !== "Default" && !taskID) {
			const temp: any = formData;
			temp.clientID = "";
			temp.clientInfo = null;
			temp.routeID = null;
			setFormData(temp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTaskType]);

	const overrideCheckForAdmin = () => {
		if (missingCustomEntities?.some((each) => Boolean(each.allowAdminToEditAfterSubmit) === true)) return false;
		return true;
	};

	return (
		<div className="user_add tasks_edit" id="user_add">
			<div className="user_add-title">
				<h1>
					{taskID ? "Edit" : "Add New"} Task ({executive?.firstName + " " + executive?.lastName})
				</h1>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<div className={`${customTaskFlag === 2 && taskTypeList.length === 0 ? "d-none" : ""}`}>
				<form className="user_add-form" onSubmit={handleSubmit}>
					{taskID && (
						<>
							<PrimaryDatePicker
								handleDateChange={(n: any) => setFormData((p) => ({ ...p, rescheduleDate: dayjs(n) }))}
								label={"Date"}
								value={formData.rescheduleDate}
								disablePast
								disabled={isSaving || disallowEdit}
							/>
							<span
								style={{
									padding: "10px",
									borderRadius: "5px",
									background: "rgba(243, 243, 243, 0.46)",
									color: "gray",
									fontSize: "14px",
								}}>
								Note: Changing the date will reschedule the task for that day.
							</span>
						</>
					)}

					{taskID && (
						<>
							<FormControl fullWidth>
								<InputLabel id="employeeID-label">Reassign Task</InputLabel>
								<Select
									labelId="employeeID-label"
									id="employeeID"
									value={formData.employeeID}
									label="Reassign Task"
									disabled={isSaving || disallowEdit}
									onChange={(e: any) =>
										setFormData((p) => {
											if (e.target.value === originalEmployeeID)
												return {
													...p,
													employeeID: e.target.value,
													repeatedTaskMetadata: p.repeatedTaskMetadata
														? { ...p.repeatedTaskMetadata, reassignEmpForAllRepeatedTasks: false }
														: null,
												};
											return { ...p, employeeID: e.target.value };
										})
									}>
									{employees.map((each) => (
										<MenuItem value={each.employeeID} key={each.employeeID}>
											{each.firstName} {each.lastName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{isRepeated && originalEmployeeID !== formData.employeeID && (
								<>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													defaultChecked
													checked={formData?.repeatedTaskMetadata?.reassignEmpForAllRepeatedTasks}
													disabled={isSaving || disallowEdit}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setFormData((prev) => ({
															...prev,
															repeatedTaskMetadata: prev.repeatedTaskMetadata
																? {
																		...prev.repeatedTaskMetadata,
																		reassignEmpForAllRepeatedTasks: event.target.checked,
																  }
																: null,
														}));
													}}
												/>
											}
											label="Reassign all repeating tasks."
										/>
									</FormControl>
									<span
										style={{
											padding: "10px",
											borderRadius: "5px",
											background: "rgba(243, 243, 243, 0.46)",
											color: "gray",
											fontSize: "14px",
										}}>
										Note: All repeating tasks will be reassigned to the new employee.
									</span>
								</>
							)}
						</>
					)}

					{[1, 2]?.includes(customTaskFlag) && (
						<>
							<FormControl className="full_width">
								<InputLabel id="select_task">Choose Custom Task</InputLabel>
								<Select
									labelId="select_task"
									label="Select any one"
									disabled={customTaskEditPhase || isSaving || disallowEdit}
									value={selectedTaskType}
									onChange={(e) => {
										setFormData((prev) => ({
											...prev,
											customFieldsComplex: null,
										}));
										setSelectedTaskType(e.target.value);
									}}>
									{[0, 1]?.includes(customTaskFlag) && <MenuItem value="Default">Default</MenuItem>}
									{taskTypeList?.map((i: any) => {
										// task type only for view
										if (i.customEntityName === "Check-In Task" && !customTaskEditPhase) {
											return null;
										}
										return (
											<MenuItem key={i.customEntityID} value={i.customEntityID}>
												{i.customEntityName}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<div
								style={{
									fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
									fontSize: "16px",
									fontWeight: 600,
									color: "gray",
									marginTop: "20px",
								}}>
								Task Details
							</div>
							<hr className="m-0 mb-2" />
						</>
					)}

					{selectedTaskType !== "Default" && (
						<div className="group">
							{/* Tasks against route does not have startTime and endTime */}
							{taskType === "routes" || formData.routeID ? null : (
								<>
									<div className="half">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												disabled={isSaving || disallowEdit}
												label="Start Time"
												value={startTime === 0 ? null : dayjs(startTime)}
												onChange={(n) => {
													const newEndTime = dayjs(n).add(30, "minutes").valueOf();
													setStartTime(dayjs(n).valueOf());
													setEndTime(newEndTime);
												}}
												slotProps={{ textField: { variant: "outlined", required: true } }}
											/>
										</LocalizationProvider>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												disabled={isSaving || disallowEdit}
												label="End Time"
												value={endTime === 0 ? null : dayjs(endTime)}
												onChange={(n) => setEndTime(dayjs(n).valueOf())}
												slotProps={{ textField: { variant: "outlined", required: true } }}
											/>
										</LocalizationProvider>
									</div>
								</>
							)}
							<FormControlLabel
								control={<Checkbox checked={formData.repeatedTaskMetadata === null ? false : true} size="small" />}
								label="Repeat Task?"
								style={{ color: "black" }}
								disabled={isSaving || disallowEdit}
								onChange={checkRepeatedTaskMetadata}
							/>
							{isRepeated && formData?.repeatedTaskMetadata && (
								<>
									<label>
										Start date
										<input
											type="date"
											required
											name="date"
											min={moment().format(DATE_TYPE_FORMAT)}
											value={formData?.date || ""}
											disabled={true}
											onChange={handleChange}
										/>
									</label>
									<label>
										Select End Date
										<input
											type="date"
											name="endDate"
											min={formData.date}
											max={moment().add(2, "months").format(DATE_TYPE_FORMAT)}
											disabled={isSaving || disallowEdit}
											value={formData?.repeatedTaskMetadata.endDate || ""}
											onChange={(e) =>
												// @ts-ignore
												setFormData((p) => ({
													...p,
													repeatedTaskMetadata: {
														...p.repeatedTaskMetadata,
														endDate: moment(e.target.value).format(DATE_TYPE_FORMAT) || null,
													},
												}))
											}
										/>
									</label>
									<div className="repeating_days">
										<h5>Repeating Days</h5>
										<ul className={`${isSaving || disallowEdit ? "disable" : ""}`}>
											<li
												onClick={() => changeRepeatingDays("monday")}
												className={`${formData.repeatedTaskMetadata.monday ? "active" : ""}`}>
												M
											</li>
											<li
												onClick={() => changeRepeatingDays("tuesday")}
												className={`${formData.repeatedTaskMetadata.tuesday ? "active" : ""}`}>
												T
											</li>
											<li
												onClick={() => changeRepeatingDays("wednesday")}
												className={`${formData.repeatedTaskMetadata.wednesday ? "active" : ""}`}>
												W
											</li>
											<li
												onClick={() => changeRepeatingDays("thursday")}
												className={`${formData.repeatedTaskMetadata.thursday ? "active" : ""}`}>
												T
											</li>
											<li
												onClick={() => changeRepeatingDays("friday")}
												className={`${formData.repeatedTaskMetadata.friday ? "active" : ""}`}>
												F
											</li>
											<li
												onClick={() => changeRepeatingDays("saturday")}
												className={`${formData.repeatedTaskMetadata.saturday ? "active" : ""}`}>
												S
											</li>
											<li
												onClick={() => changeRepeatingDays("sunday")}
												className={`${formData.repeatedTaskMetadata.sunday ? "active" : ""}`}>
												S
											</li>
										</ul>
									</div>
								</>
							)}
						</div>
					)}
					{selectedTaskType === "Default" && (
						<>
							<FormControl className="full_width">
								<InputLabel id="select_task">Task against</InputLabel>
								<Select
									labelId="select_task"
									label="Select any one"
									disabled={isSaving || disallowEdit}
									value={taskType}
									onChange={(e) => changeTaskType(e.target.value as SELECT_TASK_TYPE)}>
									<MenuItem value="clients">Clients</MenuItem>
									<MenuItem value="sites">Sites</MenuItem>
									<MenuItem value="tasks">Task Location</MenuItem>
									<MenuItem value="routes">Routes</MenuItem>
								</Select>
							</FormControl>
							{taskType === "sites" && (
								<>
									<ReactSelect
										styles={{
											menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
											menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
										}}
										isDisabled={isSaving || disallowEdit}
										value={jobType ?? ""}
										onChange={changeJobType}
										isClearable={true}
										placeholder="Search Site Type"
										className="react-select"
										options={siteTypes.map((i: any) => ({
											value: i.jobTypeID,
											label: i.jobDescription,
										}))}
									/>
									<ReactSelect
										styles={{
											menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
											menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
										}}
										value={
											formData.clientInfo?.clientName
												? { label: formData.clientInfo?.clientName, value: formData.clientInfo?.clientID }
												: ""
										}
										// @ts-ignore
										required
										onChange={changeDynamicSelection}
										isClearable={true}
										isDisabled={isSaving || disallowEdit}
										placeholder={"Search Sites"}
										className="react-select"
										options={filterdSites.map((i: SITES_DATA) => ({
											value: i.clientID,
											label: i.clientName,
											lat: i.latitude,
											lng: i.longitude,
											address: i.address,
										}))}
									/>
								</>
							)}
							{taskType === "clients" && (
								<ReactSelect
									value={labelReactSelect ? { label: labelReactSelect } : ""}
									onChange={changeDynamicSelection}
									isClearable={true}
									placeholder={"Search Clients"}
									className="react-select"
									isDisabled={isSaving || disallowEdit}
									options={clients
										?.filter((client: any) => client?.visibility !== null)
										?.map((i: PARTIAL_CLIENTS_DATA) => ({
											value: i.clientID,
											label: i.clientName,
											lat: i.lat,
											lng: i.lng,
											address: i.address,
										}))}
									formatOptionLabel={customFormatOptionLabel}
								/>
							)}
							{taskType === "routes" && (
								<ReactSelect
									defaultValue={formData?.routeID}
									onChange={changeDynamicSelection}
									isClearable={true}
									isDisabled={isSaving || disallowEdit}
									placeholder={"Search Routes"}
									className="react-select"
									options={routes?.filter((route:any) =>
										route?.routeDetails?.every(
											(r:any) => r?.type === "Client" && validClientIDs?.includes(r?.client?.clientID)// filtering out routes on the basis of the client visibility
										)
									)?.map((i: ROUTE_DATA) => ({
										value: i.routeID,
										label: i.name,
									}))}
								/>
							)}
							{taskType === "tasks" && (
								<div className="map" id="map2">
									{mapApiLoaded && (
										<SearchBox
											onPlacesChanged={onPlacesChanged}
											onChange={(e: any) => {
												setFormData((prev) => ({ ...prev, address: e.target.value }));
											}}
											maps={maps}
											value={formData.address}
											map={map}
											isDisabled={isSaving || disallowEdit}
										/>
									)}
									<GoogleMapReact
										bootstrapURLKeys={{
											key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
											libraries: ["places"],
										}}
										options={mapOptions}
										center={center}
										zoom={zoom}
										yesIWantToUseGoogleMapApiInternals
										onChange={(e) => onMapChanges(e.center, e.zoom)}
										onChildMouseDown={onMarkerInteraction}
										onChildMouseUp={onMarkerInteractionMouseUp}
										onChildMouseMove={onMarkerInteraction}
										draggable={draggable}
										onGoogleApiLoaded={({ map, maps }) => {
											apiHasLoaded(map, maps);
										}}>
										<Marker lat={formData.lat || 22.593684} lng={formData.lon || 81.96288} />
									</GoogleMapReact>
								</div>
							)}
							<TextField
								required
								disabled={isSaving || disallowEdit}
								name="taskDescription"
								label="Add Description"
								inputProps={{ maxLength: 50 }}
								value={formData?.taskDescription || ""}
								onChange={handleChange}
							/>
						</>
					)}
					{selectedTaskType === "Default" && (
						<div className="custom_fields_complex">
							<div className="title">
								<h5>Custom Fields</h5>
								<Link prefetch={false} href="/settings/custom-fields?entity=Tasks" target="blank ">
									<AddIcon />
								</Link>
								<button disabled={loadingEntity} className="refetch" type="button" onClick={() => refetchEntity()}>
									<RefreshOutlinedIcon />
								</button>
							</div>

							{missingEntities && Array.isArray(missingEntities) && missingEntities.length > 0 && (
								<CustomFieldsComplex
									fieldsData={formData}
									setFieldsData={setFormData}
									missingEntities={missingEntities}
									setImageOrFileError={setImageOrFileError}
									setCustomFieldErrors={setCustomFieldErrors}
									isDisabled={isSaving || disallowEdit}
									overrideIsRequiredForAdmins={true}
								/>
							)}
						</div>
					)}
					{selectedTaskType !== "Default" && (
						<div className="custom_fields_complex" style={{ backgroundColor: "white", padding: 0 }}>
							{missingCustomEntities &&
								!loadingCustomEntity &&
								Array.isArray(missingCustomEntities) &&
								missingCustomEntities.length > 0 && (
									<CustomFieldsComplex
										fieldsData={formData}
										setFieldsData={setFormData}
										missingEntities={missingCustomEntities}
										setImageOrFileError={setImageOrFileError}
										setCustomFieldErrors={setCustomFieldErrors}
										isDisabled={isSaving || disallowEdit}
										overrideIsRequiredForAdmins={true}
										clients={dataClients?.get_clients_by_employeeID || []}
										routes={dataRoutes?.get_routes || []}
										sites={dataSites?.sites || []}
										changeJobType={changeJobType}
										jobType={jobType}
										siteTypes={dataSiteTypes?.siteTypes || []}
										filterdSites={filterdSites}
										mapOptions={mapOptions}
										center={center}
										zoom={zoom}
										onMapChanges={onMapChanges}
										onMarkerInteraction={onMarkerInteraction}
										onMarkerInteractionMouseUp={onMarkerInteractionMouseUp}
										draggable={draggable}
										apiHasLoaded={apiHasLoaded}
										mapApiLoaded={mapApiLoaded}
										onPlacesChanged={onPlacesChanged}
										maps={maps}
										googleMap={map}
										editTask={!!currentTask}
										selectedTaskType={selectedTaskType}
										isClientAddressMasked={isClientAddressMasked}
									/>
								)}
							{loadingCustomEntity && (
								<Stack spacing={1.5}>
									<Skeleton variant="rounded" height={36} animation="wave" />
									<Skeleton variant="rounded" height={36} animation="wave" />
									<Skeleton variant="rounded" height={36} animation="wave" />
									<Skeleton variant="rounded" height={36} animation="wave" />
								</Stack>
							)}
						</div>
					)}
					{selectedTaskType === "Default" && (
						<div className="group">
							{taskType === "routes" ? null : (
								<>
									<div className="half">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												disabled={isSaving || disallowEdit}
												label="Start Time"
												value={startTime === 0 ? null : dayjs(startTime)}
												onChange={(n) => {
													const newEndTime = dayjs(n).add(30, "minutes").valueOf();
													setStartTime(dayjs(n).valueOf());
													setEndTime(newEndTime);
												}}
												slotProps={{ textField: { variant: "outlined", required: true } }}
											/>
										</LocalizationProvider>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												disabled={isSaving || disallowEdit}
												label="End Time"
												value={endTime === 0 ? null : dayjs(endTime)}
												onChange={(n) => setEndTime(dayjs(n).valueOf())}
												slotProps={{ textField: { variant: "outlined", required: true } }}
											/>
										</LocalizationProvider>
									</div>
								</>
							)}
							<FormControlLabel
								control={<Checkbox checked={formData.repeatedTaskMetadata === null ? false : true} size="small" />}
								label="Repeat Task?"
								style={{ color: "black" }}
								disabled={isSaving || disallowEdit}
								onChange={checkRepeatedTaskMetadata}
							/>
							{isRepeated && formData?.repeatedTaskMetadata && (
								<>
									<label>
										Start date
										<input
											type="date"
											required
											name="date"
											min={moment().format(DATE_TYPE_FORMAT)}
											value={formData?.date || ""}
											disabled={true}
											onChange={handleChange}
										/>
									</label>
									<label>
										Select End Date
										<input
											type="date"
											name="endDate"
											min={formData.date}
											max={moment().add(2, "months").format(DATE_TYPE_FORMAT)}
											disabled={isSaving || disallowEdit}
											value={formData?.repeatedTaskMetadata.endDate || ""}
											onChange={(e) =>
												// @ts-ignore
												setFormData((p) => ({
													...p,
													repeatedTaskMetadata: {
														...p.repeatedTaskMetadata,
														endDate: moment(e.target.value).format(DATE_TYPE_FORMAT) || null,
													},
												}))
											}
										/>
									</label>
									<div className="repeating_days">
										<h5>Repeating Days</h5>
										<ul className={`${isSaving || disallowEdit ? "disable" : ""}`}>
											<li
												onClick={() => changeRepeatingDays("monday")}
												className={`${formData.repeatedTaskMetadata.monday ? "active" : ""}`}>
												M
											</li>
											<li
												onClick={() => changeRepeatingDays("tuesday")}
												className={`${formData.repeatedTaskMetadata.tuesday ? "active" : ""}`}>
												T
											</li>
											<li
												onClick={() => changeRepeatingDays("wednesday")}
												className={`${formData.repeatedTaskMetadata.wednesday ? "active" : ""}`}>
												W
											</li>
											<li
												onClick={() => changeRepeatingDays("thursday")}
												className={`${formData.repeatedTaskMetadata.thursday ? "active" : ""}`}>
												T
											</li>
											<li
												onClick={() => changeRepeatingDays("friday")}
												className={`${formData.repeatedTaskMetadata.friday ? "active" : ""}`}>
												F
											</li>
											<li
												onClick={() => changeRepeatingDays("saturday")}
												className={`${formData.repeatedTaskMetadata.saturday ? "active" : ""}`}>
												S
											</li>
											<li
												onClick={() => changeRepeatingDays("sunday")}
												className={`${formData.repeatedTaskMetadata.sunday ? "active" : ""}`}>
												S
											</li>
										</ul>
									</div>
								</>
							)}
						</div>
					)}
					<ul className="errors">
						{errors.map((e, i) => (
							<li key={i}>{e}</li>
						))}
					</ul>

					<div className="action_buttons">
						<button
							type="reset"
							disabled={
								currentTask?.checkinTime && currentTask?.checkoutTime
									? overrideCheckForAdmin()
									: isSaving || disallowEdit
							}
							onClick={() => setExpanded(false)}>
							Cancel
						</button>
						<div>
							{taskID && !formData.checkinTime ? (
								<button
									type="reset"
									style={{ marginRight: "10px" }}
									disabled={isSaving || disallowEdit}
									onClick={() => handleClickOpen()}>
									Delete
								</button>
							) : null}
							<button
								type="submit"
								disabled={
									currentTask?.checkinTime && currentTask?.checkoutTime
										? overrideCheckForAdmin()
										: isSaving || disallowEdit
								}>
								{isSaving ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : "Save"}
							</button>
						</div>
					</div>
				</form>
			</div>
			{customTaskFlag === 2 && taskTypeList?.length === 0 && !taskTypeListLoading && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "10px",
					}}>
					<img
						src="/images/add-fields-to-view.svg"
						alt="empty-state"
						style={{
							width: "75%",
							height: "75%",
							maxWidth: "300px",
							margin: "0 auto",
						}}
					/>
					<p
						style={{
							fontSize: "14px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							marginTop: "10px",
						}}>
						No custom task type found !
					</p>
					<p
						style={{
							fontSize: "14px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							marginTop: "10px",
						}}>
						Please create a custom task type from{" "}
						<Link href="/settings/tasks" target="_blank">
							here
						</Link>{" "}
						to add task
					</p>
				</div>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				container={() => document.getElementById("user_add")}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{isRepeated && formData?.repeatedTaskMetadata ? (
							<div>
								<legend style={{ borderBottom: 0, marginBottom: "10px", color: "black" }}>
									Remove recurring task{" "}
								</legend>
								<label style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
									<input
										type="radio"
										onChange={() => setDeleteFutureTask(false)}
										name="deleteTask"
										id="tasks"
										value="task"
										checked={!deleteFutureTask}
									/>
									<p style={{ margin: 0, marginLeft: "5px", color: "#42444a" }}>This task</p>
								</label>
								<label style={{ display: "flex", alignItems: "center" }}>
									<input
										type="radio"
										onChange={() => setDeleteFutureTask(true)}
										name="deleteTask"
										id="tasks"
										value="task"
										checked={deleteFutureTask}
									/>
									<p style={{ margin: 0, marginLeft: "5px", color: "#42444a" }}>This and following tasks</p>
								</label>
							</div>
						) : (
							<div className="modal-body">Are you sure you want to delete task?</div>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div className="action_buttons">
						<button
							type="reset"
							style={{
								backgroundColor: "#f54747",
								color: "white",
								borderRadius: 5,
								height: "30px",
								marginRight: 10,
								padding: "5px 15px",
							}}
							onClick={() => handleClose()}
							data-dismiss="modal">
							Cancel
						</button>
						<button
							type="button"
							style={{
								backgroundColor: "#1976d2",
								color: "white",
								borderRadius: 5,
								height: "30px",
								padding: "5px 10px",
							}}
							onClick={() => {
								handleDelete(deleteFutureTask);
							}}>
							OK
						</button>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default TaskFormSidebar;

const Marker = ({ lat, lng }: any) => <div className="wrapper"></div>;
