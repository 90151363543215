export default function polylineDecoder(encoded) {
	let index = 0;
	const len = encoded.length;
	const path = [];
	let lat = 0;
	let lng = 0;

	// Decoding the polyline
	while (index < len) {
		let b,
			shift = 0,
			result = 0;
		do {
			b = encoded.charCodeAt(index++) - 63;
			result |= (b & 0x1f) << shift;
			shift += 5;
		} while (b >= 0x20);
		const deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
		lat += deltaLat;

		shift = 0;
		result = 0;
		do {
			b = encoded.charCodeAt(index++) - 63;
			result |= (b & 0x1f) << shift;
			shift += 5;
		} while (b >= 0x20);
		const deltaLng = result & 1 ? ~(result >> 1) : result >> 1;
		lng += deltaLng;

		path.push([lng * 1e-5, lat * 1e-5]);
	}

	// Repeat the first coordinate at the end if path is not empty
	if (path.length > 0) {
		path.push(path[0]);
	}

	// Calculate the center of the polyline
	let totalLat = 0;
	let totalLng = 0;

	// Loop through all the coordinates to sum up latitude and longitude
	path.forEach(([lng, lat]) => {
		totalLat += lat;
		totalLng += lng;
	});

	// Calculate the average of the latitudes and longitudes
	const centerLat = totalLat / path.length;
	const centerLng = totalLng / path.length;
	const center = { lng: centerLng, lat: centerLat };

	// Return both the path and the center
	return { path, center };
}
