//react imports
import { FC, Dispatch } from "react";
//liberary
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import DeckGL from "@deck.gl/react";
import { random } from "@luma.gl/shadertools";

import { FlyToInterpolator } from "@deck.gl/core";

//types , utils and components
import IconClusterLayer from "./IconClusterLayer";
import TextClusterLayer from "./TextClusterLayer";
import { ScatterplotLayer, PolygonLayer } from "@deck.gl/layers";
import polylineDecoder from "./polylineDecoder";
import { CLIENTS_DATA } from "../../../@types";

type allClients = {
	point: CLIENTS_DATA[];
	geo?: CLIENTS_DATA[];
};
type ViewState = {
	latitude: number;
	longitude: number;
	zoom: number;
	minZoom: number;
	transitionInterpolator?: FlyToInterpolator;
	transitionDuration?: number | "auto";
};

type Props = {
	AllClients: allClients;
	setSelectedClientId: Dispatch<any>;
	setExpanded: Dispatch<boolean>;
	initialViewState: ViewState;
	setInitialViewState: Dispatch<ViewState>;
	flyToCity: (e: any) => void;
	circleCord: any;
	setCircleCord?: any;
};

const DeckGlClientMap: FC<Props> = ({
	AllClients,
	setSelectedClientId,
	setExpanded,
	initialViewState,
	flyToCity,
	circleCord,
	setCircleCord,
}) => {
	const layers = [
		AllClients?.geo
			? new PolygonLayer({
					id: "polygon-layer",
					data: AllClients?.geo.map((site: any) => {
						const newObj = { contour: [polylineDecoder(site.polyline).path], ...site };
						return newObj;
					}),
					getPolygon: (d) => d.contour,
					getFillColor: [187, 191, 188, 120], // Green color with 50% opacity (RGBA format)
					getLineColor: [98, 99, 99, 255], // Black outline
					getLineWidth: 1,
					filled: true,
					stroked: true,
					lineWidthMinPixels: 1,
					pickable: true,
			  })
			: null,
		circleCord.show
			? new ScatterplotLayer({
					id: "circle-layer",
					data: circleCord.point,
					getPosition: (d) => d.position,
					getRadius: (d) => d.radius,
					getColor: [136, 196, 244, 90],
					getLineWidth: 1,
					getLineColor: [79, 194, 247],
					stroked: true,
					updateTriggers: {
						getRadius: (d: any) => d.radius,
					},
			  })
			: null,
		new IconClusterLayer({
			data: [
				...(AllClients.point || []),
				// @ts-ignore
				...(AllClients?.geo?.map((oneGeo) => ({
					...oneGeo,
					lat: polylineDecoder(oneGeo.polyline).center.lat,
					lng: polylineDecoder(oneGeo.polyline).center.lng,
					coordinates: [polylineDecoder(oneGeo.polyline).center.lng, polylineDecoder(oneGeo.polyline).center.lat],
					latitude: polylineDecoder(oneGeo.polyline).center.lat,
					longitude: polylineDecoder(oneGeo.polyline).center.lng,
				})) || []),
			],
			pickable: true,
			// @ts-ignore
			getPosition: (d: any) => d.coordinates,
			getIcon: () => "./cluster.svg",
			id: `icon-cluster ${random.name}`,
			sizeScale: 42,
			onClick: (e: any) => {
				flyToCity(e);
				if (e.hasOwnProperty("cluster") && !e.cluster) {
					setSelectedClientId(e.info.clientID);
					setExpanded(true);
					if (setCircleCord) setCircleCord({ show: true, point: [e.info] });
				}
			},
		}),
		new TextClusterLayer({
			id: "labels",
			data: AllClients?.geo
				? [
						...AllClients?.point,
						...AllClients?.geo.map((oneGeo) => ({
							...oneGeo,
							lat: polylineDecoder(oneGeo.polyline).center.lat,
							lng: polylineDecoder(oneGeo.polyline).center.lng,
							coordinates: [polylineDecoder(oneGeo.polyline).center.lng, polylineDecoder(oneGeo.polyline).center.lat],
							latitude: polylineDecoder(oneGeo.polyline).center.lat,
							longitude: polylineDecoder(oneGeo.polyline).center.lng,
						})),
				  ]
				: [...AllClients.point],
			// @ts-ignore
			getText: (d: any) => d.name,
			getPosition: (d: any) => d.coordinates,
			getSize: 12,
			sizeScale: 42,
		}),
	];
	return (
		<div>
			<APIProvider apiKey={`${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`}>
				<DeckGL initialViewState={initialViewState} layers={layers} controller={{ dragRotate: false }}>
					<Map
						defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
						defaultZoom={1}
						mapId={process.env.NEXT_PUBLIC_MAP_ID}
						gestureHandling={"greedy"}
						minZoom={3}></Map>
				</DeckGL>
			</APIProvider>
		</div>
	);
};

export default DeckGlClientMap;
