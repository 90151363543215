import { useQuery } from "react-query";
import { fetchAdminEntitlements, fetchCompanySettings } from "../utils";

// this has to be removed later on
const tempCustomReportModule = [
	"customReports:attendance",
	"customReports:expenses",
	"customReports:sales",
	"customReports:productivity",
	"customReports:locationAndTravel",
	"customReports:organization",
	"customReports:client",
];
export default function useAdminEntitlements(module: string, mode: string) {
	const { data } = useQuery("adminEntitlements", fetchAdminEntitlements, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});
	const adminEntitlements = data?.data?.data;
	// this has to be removed later on
	if (localStorage.getItem("unolouser") && adminEntitlements && tempCustomReportModule.includes(module)) {
		return adminEntitlements && adminEntitlements[module] ? true : false;
	}
	return adminEntitlements && adminEntitlements[module] ? adminEntitlements[module][mode] : true;
}

export const useAdminCompanySettings = (module: string) => {
	const { data } = useQuery("companySettings", fetchCompanySettings, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});
	const companySettings = data?.data?.data;
	return companySettings && companySettings[module] ? companySettings[module] : false;
};
